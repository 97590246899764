import React from "react";

export default ({className, text, onClick}) => {
    return (
        <button
            className={"button " + className}
            onClick={onClick}
        >
            {text}
        </button>
    )
}