import React from "react";
import img from 'assets/image/partnerEl.png'
import Api from "../../service/Api";
import {Link} from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import El from "./el";
import translate from "../../page/forumDayEvents/_translate";

class Partners extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    Api = new Api()

    state = {
        partners: undefined,
        show: false
    }

    componentDidMount() {
        this.getPartners();
    }

    getPartners = () => {
        this.Api.getAllProjects(this.props.forum_id)
            .then(partners => this.setState({partners}))
            .catch(e => console.log('error', e))
    }

    render() {
        const {partners, show} = this.state;
        const {translate, lang} = this.props;
        return (
            <div className='partners vmeste'>
                <div className="cnt">
                    <div className="partners__head">
                        {translate.project.head[lang]}
                    </div>
                    <div className="partners__flex">
                        {partners && Object.values(partners).map((partner => {
                            return (
                              <El partner={partner}/>
                            )
                        }))}
                    </div>
                </div>
            </div>
        )
    }
}
export default withCookies(Partners)