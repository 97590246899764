import React from "react";
import Header from "../../pageComponents/header";
import Banner from "../../pageComponents/banner";
import bg from "../../assets/image/forumDayEventsBg.svg";
import bgPeople from "../../assets/image/forumDayEventsPeople.svg";
import Speaker from "../../pageComponents/speaker";
import Programs from "../../pageComponents/programs";
import Organizers from "../../pageComponents/organizers";
import Partners from "../../pageComponents/partners";
import Projects from "../../pageComponents/projects";
import Button from "../../components/button";
import Footer from "../../pageComponents/footer";
import translate from "./_translate";
import {useCookies} from "react-cookie";

const DobroTemplate = ({user, forumData, showAllProjects, forumId, getUserData, getForumData, setShowAllProjects}) => {
  const [cookies] = useCookies(['lang']);
  let lang = cookies.lang || 'ru'
    return (
        <div className="forumDatEvents__page page">
            <Header className={'header__orange'} user={user} getUserData={getUserData}/>
            {forumData ?
                <Banner
                    bg={bg}
                    bgPeople={bgPeople}
                    banner__date_start={forumData.forum.date_start}
                    banner__date_end={forumData.forum.date_end}
                    banner__head={forumData.forum.name}
                    banner__text={forumData.forum.description}
                    // banner__btn={user ? `Добавить в мои мероприятия` : 'Регистрация'}
                />
                :
                <Banner
                    bg={bg}
                    bgPeople={bgPeople}
                />
            }
            {forumData && forumData.forumSpeaker && <Speaker className={'vmesteSpeaker'} speakers={forumData.forumSpeaker }/>}
            {forumData && forumData.forumDays &&
            <Programs
                forum_id={forumId}
                user={user}
                serverTime={forumData.server.date}
                timeZone={forumData.forum.timeZone}
                forumDays={forumData.forumDays}
                forumDaysEvent={forumData.forumDaysEvent}
                forumDaysEventSpeakers={forumData.forumDaysEventSpeakers}
                userEvents={forumData.userEvents}
                getForumData={getForumData}
                translate={translate}
                lang={lang}
            />
            }
            <Organizers forum_id={forumId}/>
            <Partners forum_id={forumId}/>
            <div className={"projects__all "  + (showAllProjects && 'show')}>
                <Projects showDesc={'true'} forum_id={forumId}/>
                {!showAllProjects &&
                <div className="partners__btns">
                    <Button
                        className={'button__orange'}
                        onClick={() => {
                            setShowAllProjects(true)
                        }}
                        text={'Смотреть все'}
                    />
                </div>
                }
            </div>
            <Footer/>
        </div>
    )
}

export default DobroTemplate