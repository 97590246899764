import React from "react";
import Api from "../../service/Api";
import Header from 'pageComponents/header'
import Footer from 'pageComponents/footer'
import Slider from "react-slick";



export default class Partners extends React.Component{
    Api = new Api()

    state = {
        user: undefined,
        partners: undefined,
        galleryImgShow: undefined
    }

    componentDidMount() {
        this.getUserData();
        this.getPartnerData();
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    getPartnerData = () => {

        this.Api.getPartner(this.props.match.params.id)
            .then(partners => {
                if(!partners){
                    this.props.history.push('/');
                }else{
                    this.setState({partners})
                }
            })
    }

    galleryImgShow = (id) => {
        const {galleryImgShow} = this.state
        if(galleryImgShow === id){
            this.setState({galleryImgShow: undefined})
        }else {
            this.setState({galleryImgShow: id})
        }
    }

    render() {
        const {user, partners, galleryImgShow} = this.state
        if(!partners){
            return <div/>
        }
        const settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: false,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div className="partners__page page">
                <Header
                    user={user}
                    className={'header__orange'}
                    style={{backgroundColor: partners ? partners.ForumPartners.color : ''}}
                />
                <div className="partners">
                    <div className="cnt">
                        <img src={'https://dobroinrussia.online/uploads'+ partners.ForumPartners.logo} alt="" className={'partners__logo'}/>
                        <div className="partners__name">
                            {partners.ForumPartners.name}
                        </div>
                        <div className="partners__desc" dangerouslySetInnerHTML={{__html: partners.ForumPartners.description}}/>

                        {partners.ForumPartners.video &&
                            <iframe title={partners.ForumPartners.name} className={'partners__video'} src={partners.ForumPartners.video} frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        }

                        {partners.ForumPartners.text &&
                            <div className="partners__about">
                                <div className="head">
                                    О нас
                                </div>
                                <div className="partners__text" dangerouslySetInnerHTML={{__html: partners.ForumPartners.text}}/>
                            </div>
                        }

                        {partners.ForumPartnersPhoto && partners.ForumPartnersPhoto.length &&
                        <div className="gallery">
                            <div className="gallery__head">
                                Галерея
                            </div>
                            <Slider {...settings}>
                                { partners.ForumPartnersPhoto.map((photo, index) => {
                                    return (
                                        <div
                                            key={photo.id + +new Date() + index}
                                            className={'gallery__el ' + (galleryImgShow === photo.id ? 'show' : '')}
                                            // onClick={() => this.galleryImgShow(photo.id)}
                                        >
                                            <img src={'https://dobroinrussia.online/uploads'+ photo.path} alt=""/>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        }

                        {partners.ForumPartners.text2 &&
                            <div className="partners__text" dangerouslySetInnerHTML={{__html: partners.ForumPartners.text2}}/>
                        }
                        {partners.ForumPartners.text3 &&
                            <div className="partners__text" dangerouslySetInnerHTML={{__html: partners.ForumPartners.text3}}/>
                        }
                    </div>

                    {partners.ForumPartners.contact && partners.ForumPartners.contact.length &&
                        <div className="partners__contact">
                            <div className="cnt">
                                <div className="contact__head">Контакты</div>
                                <div
                                    className="contact"
                                    style={{
                                        background: partners.ForumPartners.color,
                                        color: partners.ForumPartners.color,
                                    }}
                                    dangerouslySetInnerHTML={{__html: partners.ForumPartners.contact}}
                                />
                            </div>
                        </div>
                    }
                </div>


                <Footer/>
            </div>
        )
    }

}


