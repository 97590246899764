import React, {useState} from "react";
import part1 from '../../assets/image/part_ros.png'
import part2 from '../../assets/image/part_ros2.png'
import part3 from '../../assets/image/part_avc.png'
import glass from '../../assets/image/glass.png'
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";

const HeaderVmeste = ({user, translate, lang, bg}) => {
  const [cookies, setCookie] = useCookies(['lang']);
  const [showLogin, setShowLogin] = useState(false);
  let origin = window.location.origin
  let pathname = window.location.pathname
  pathname = pathname.replace('/en', '')
  pathname = pathname.replace('/ru', '')
  return (
    <header className={"headerVmeste " + (bg && 'bg')}>
      {pathname.indexOf('profile') >= 0
        ? <Link to={'/' + lang} className="logo">
          {translate.header.logo[lang]}
        </Link>
        : <a href='https://xn--2020-f4dsa7cb5cl7h.xn--p1ai/' target="blank" className="logo">
          {translate.header.logo[lang]}
        </a>
      }

      <div className="partners">
        <a href="https://fadm.gov.ru/" target="blank" className="partners_el">
          <img src={part1} alt=""/>
        </a>
        <a href="https://rospatriotcentr.ru/" target="blank" className="partners_el">
          <img src={part2} alt=""/>
        </a>
        <a href=" https://xn--80ae4d.xn--p1ai/ " target="blank" className="partners_el">
          <img src={part3} alt=""/>
        </a>
      </div>
      {/*<div className="special">*/}
      {/*    <div className="special__icon">*/}
      {/*        <img src={glass} alt=""/>*/}
      {/*    </div>*/}
      {/*    Специальные возможности*/}
      {/*</div>*/}
      {!user
        ? <div className="login">
          <div className="click" onClick={() => {
            setShowLogin(!showLogin)
          }}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="white"/>
              <path d="M28 27V25C28 22.7909 26.2091 21 24 21H16C13.7909 21 12 22.7909 12 25V27"
                    stroke="#FF6700" stroke-width="2"/>
              <circle cx="20" cy="13" r="4" stroke="#FF6700" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
            </svg>
            <span style={{whiteSpace: "nowrap"}}>
                  {translate.header.bnt[lang]}
                </span>
          </div>
          <div className={"down " + (showLogin && 'open')}>
            <a href={'https://dobroinrussia.online/site/login'} className="down__el">Я гражданин РФ /<br/> I am a Russian citizen</a>
            <a href={'https://dobroinrussia.online/auth/future-team-login'} className="down__el">Я иностранный гражданин /<br/> I am a citizen of a foreign country</a>
          </div>
        </div>
        : <Link to={"/" + (cookies.lang || 'ru') + '/profile'}
                className="login">

          <div className="click">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="white"/>
              <path d="M28 27V25C28 22.7909 26.2091 21 24 21H16C13.7909 21 12 22.7909 12 25V27"
                    stroke="#FF6700" strokeWidth="2"/>
              <circle cx="20" cy="13" r="4" stroke="#FF6700" strokeWidth="2" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
            <span className="header__account-text">
                                    {user.name} {user.surname} <br/>
                            </span>
          </div>
        </Link>
      }

      <div className="lang">
        <div
          className={"lang__el " + (cookies.lang === 'ru' && 'lang__el-active')}
          onClick={() => {
            setCookie('lang', 'ru', {path: '/'})
            document.location.replace(origin + '/ru' + pathname.replace('/en', '').replace('/ru', ''))

          }}
        >
          РУС
        </div>
        <div
          // href={"/en" + pathname}
          className={"lang__el " + (cookies.lang === 'en' && 'lang__el-active')}
          onClick={() => {
            setCookie('lang', 'en', {path: '/'})
            document.location.replace(origin + '/en' + pathname.replace('/en', '').replace('/ru', ''))
          }}
        >
          ENG
        </div>
      </div>
    </header>
  )
}

export default HeaderVmeste
