import React from "react";
import {Link} from 'react-router-dom'
import Menu from 'pageComponents/header/menu'
export default ({className}) => {
    return (
        <footer className={"footer " + className}>
            <div className="cnt">
                <div className="footer__row">
                    {/*<Menu/>*/}
                </div>
                <div className="footer__row">
                    <a
                        href="tel:88002223492"
                        className={'footer__row-el'}
                    >Техническая поддержка - 8(800)2223492</a>
                    <div className="footer__row-el">
                        © 2018–{new Date().getFullYear()} ФГБУ «Роспатриотцентр», 0+
                    </div>
                    <div className="footer__row-el">
                        <div className="social">
                            <a
                                href={'https://vk.com/dobroinrussia'}
                                target={'_blank'}
                                className={'social__el social__el-vk'}
                            >
                                <svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.87704 0.740234C2.91979 0.740234 4.50876 0.740234 5.40255 0.740234C5.84944 0.740234 6.19703 1.03489 6.346 1.42777C6.79289 2.70463 7.78599 5.50389 8.92806 7.41918C10.4674 9.92378 11.4605 10.8569 12.106 10.7587C12.7515 10.6113 12.5529 8.94159 12.5529 7.61562C12.5529 6.28965 12.7019 4.0306 12.0563 2.99929L11.0632 1.5751C10.815 1.23133 11.0632 0.789344 11.4605 0.789344H17.1212C17.6674 0.789344 18.1143 1.23133 18.1143 1.77154V8.89248C18.1143 8.89248 18.3625 10.1693 19.7529 8.84337C21.1432 7.5174 22.6329 5.0619 23.8743 2.5573L24.3708 1.37866C24.5198 1.03489 24.8674 0.789344 25.2646 0.789344H28.9391C29.6342 0.789344 30.1308 1.47688 29.8825 2.11531L29.4853 3.14662C29.4853 3.14662 28.1446 5.79855 26.7543 7.66473C25.3639 9.58001 25.066 10.022 25.2646 10.5131C25.4632 11.0042 29.0384 14.2946 29.9322 15.8661C30.1805 16.3081 30.3791 16.7009 30.5777 17.0447C30.9253 17.6831 30.4287 18.518 29.6839 18.518H25.5129C25.1653 18.518 24.8177 18.3216 24.6687 18.0269L24.2715 17.3885C24.2715 17.3885 21.7391 14.4419 20.1998 13.5088C18.6108 12.6248 18.6605 13.9017 18.6605 13.9017V16.5045C18.6605 17.5849 17.7667 18.4689 16.6743 18.4689H15.6812C15.6812 18.4689 10.2191 18.4689 5.84944 12.0355C2.42324 7.0263 1.28117 3.78505 0.883933 1.91887C0.784622 1.32955 1.23152 0.740234 1.87704 0.740234Z" fill="#2688E3"/>
                                </svg>
                            </a>
                            {/*<Link*/}
                            {/*    to={'#'}*/}
                            {/*    className={'social__el social__el-fb'}*/}
                            {/*>*/}
                            {/*    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*        <path fillRule="evenodd" clipRule="evenodd" d="M11 0H8C5.23858 0 3 2.12665 3 4.75V7.6H0V11.4H3V19H7V11.4H10L11 7.6H7V4.75C7 4.22533 7.44772 3.8 8 3.8H11V0Z" fill="#2649C7"/>*/}
                            {/*    </svg>*/}


                            {/*</Link>*/}
                            <a
                                href={'https://www.instagram.com/dobroinrussia/'}
                                target={'_blank'}
                                className={'social__el social__el-insta'}
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM14.451 9.33998C14.7357 11.2603 13.7569 13.1467 12.0229 14.0194C10.2889 14.8922 8.19063 14.5546 6.81795 13.1819C5.44526 11.8092 5.10763 9.71096 5.9804 7.97695C6.85318 6.24294 8.73959 5.26412 10.6599 5.54887C12.6206 5.83963 14.1602 7.37921 14.451 9.33998ZM17.5 3.75C17.5 4.44036 16.9404 5 16.25 5C15.5596 5 15 4.44036 15 3.75C15 3.05964 15.5596 2.5 16.25 2.5C16.9404 2.5 17.5 3.05964 17.5 3.75Z" fill="url(#paint0_linear)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="20" y1="0" x2="3" y2="20" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#F536B4"/>
                                            <stop offset="1" stopColor="#F18018"/>
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </a>
                            <a
                                href={'https://teleg.one/dobroinrussia '}
                                target={'_blank'}
                                className={'social__el social__el-teleg'}
                            >
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.53416 13.1054L8.39478 20.2564L12.1189 16.5323L18.5041 21.6074L23 1.39258L0 10.9729L5.53416 13.1054ZM16.4277 7.19241L9.38779 13.6136L8.51094 16.9184L6.89112 12.868L16.4277 7.19241Z" fill="#48A2F5"/>
                                </svg>
                            </a>
                            <a
                                href={'https://ok.ru/dobroinrussia'}
                                target={'_blank'}
                                className={'social__el social__el-ok'}
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0)">
                                        <path d="M9.99992 0C7.24247 0 5 2.24247 5 4.99992C5 7.75738 7.24247 9.99984 9.99992 9.99984C12.7574 9.99984 14.9998 7.75738 14.9998 4.99992C14.9998 2.24247 12.7574 0 9.99992 0ZM9.99992 7.49988C8.62119 7.49988 7.49996 6.37865 7.49996 4.99992C7.49996 3.62119 8.62119 2.49996 9.99992 2.49996C11.3787 2.49996 12.4999 3.62119 12.4999 4.99992C12.4999 6.37865 11.3787 7.49988 9.99992 7.49988Z" fill="#FF9518"/>
                                        <path d="M16.63 9.86113C16.1412 9.37238 15.3513 9.37238 14.8625 9.86113C12.1826 12.5411 7.82012 12.5411 5.13891 9.86113C4.65017 9.37238 3.86018 9.37238 3.37144 9.86113C2.8827 10.3499 2.8827 11.1399 3.37144 11.6299C4.92641 13.1848 6.89763 14.0711 8.9301 14.3023L5.36641 17.866C4.87767 18.3548 4.87767 19.1448 5.36641 19.6335C5.85515 20.1222 6.64514 20.1222 7.13388 19.6335L10.0001 16.7673L12.8663 19.6335C13.11 19.8772 13.43 19.9997 13.75 19.9997C14.07 19.9997 14.39 19.8772 14.6338 19.6335C15.1225 19.1448 15.1225 18.3548 14.6338 17.866L11.0701 14.3023C13.1025 14.0711 15.0738 13.1848 16.6287 11.6299C17.1175 11.1411 17.1175 10.3499 16.63 9.86113Z" fill="#FF9518"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect width="20" height="20" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                            </a>
                        </div>
                    </div>
                    <div
                        className="footer__row-el onTop"
                        onClick={() => {window.scrollTo(0, 0)}}
                    >
                        Наверх
                    </div>
                </div>
            </div>
        </footer>
    )
}