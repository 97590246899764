import React from "react";
import {Link} from "react-router-dom";
import {useCookies} from "react-cookie";


export default ({navListShow}) => {
    const [cookies] = useCookies(['lang']);
    return (
            <div className={"navigation__list " + (navListShow ? 'show' : '')}>
                <Link to={"/" + (cookies.lang || 'ru') + "/forums"} className="navigation__list-el">Мероприятия</Link>
                <Link to={"/" + (cookies.lang || 'ru') + "/training"} className="navigation__list-el">Обучение</Link>
                <Link to={"/" + (cookies.lang || 'ru') + "/test"} className="navigation__list-el">Тестирование</Link>
            </div>

    )
}