const translate = {
  header: {
    logo: {
      ru: '#МЫВМЕСТЕ',
      en: '#WEARETOGETHER'
    },
    bnt: {
      ru: 'Войти',
      en: 'Log in'
    }
  },
  banner : {
    head: {
      ru: 'Онлайн марафон #МЫВМЕСТЕ',
      en: 'Online marathon #WEARETOGETHER'
    },
    subhead: {
      ru: 'Главное волонтерское событие года!',
      en: 'The main volunteer event of the year!',
    },
    text1: {
      ru: '2020 — год взаимопомощи и силы людей. Год, когда мы поняли, что #МЫВМЕСТЕ. Пандемия подтолкнула общество к слому формальностей и обороне плечом к плечу вне зависимости от профессий и возраста. Люди развозили продукты, компании кормили врачей и обеспечивали волонтеров техникой, а министры социальной защиты реагировали на запросы НКО. Теперь наша задача — сохранить и приумножить этот опыт, потому что когда #МЫВМЕСТЕ, мы можем всё.',
      en: '2020 is the year of mutual help and strength of people. The year when we realized that #WEARETOGETHER. The pandemic has pushed society to break formalities and defend shoulder to shoulder, regardless of professions and age. People delivered food, companies fed doctors and provided volunteers with equipment, and social welfare ministers responded to requests from NGOs. Now our task is to preserve and multiply this experience, because when #WEARETOGETHER, we can do everything.',
    },
    text2: {
      ru: 'Марафон #МЫВМЕСТЕ объединит нас, чтобы отпраздновать День добровольца!',
      en: 'The #WEARETOGETHER Marathon will unite us to celebrate Volunteer Day!',
    },
    text3: {
      ru: '4-5 декабря 2020 года Площадки: VK.COM, DOBRO.RU',
      en: 'December 4-5, 2020 Sites: VK.COM, DOBRO.RU',
    },
    text4: {
      ru: 'Участие в марафоне может принять каждый!',
      en: 'Everyone can take part in the marathon!',
    },
    btn: {
      ru: 'Регистрация',
      en: 'Log in',
    },
  },
  about: {
    head: {
      ru: 'О марафоне',
      en: 'About the Marathon '
    },
    text1: {
      ru: '36 часов трансляции',
      en: '36 hours of broadcast'
    },
    text2: {
      ru: 'Вещание из регионов России <br> и иностранных государств',
      en: 'Broadcasting from various regions of Russia and foreign countries'
    },
    text3: {
      ru: 'Участники со всего мира',
      en: 'Participants from all over the world'
    },
    text4: {
      ru: 'Программа с участием спикеров, звезд <br> и экспертов',
      en: 'Program for speakers, stars and experts'
    },
    btn: {
      ru: 'Посмотреть программу',
      en: 'View the program'
    },

  },
  country: {
    head: {
      ru: 'Как страна отпразднует День добровольца',
      en: 'How the country is going to celebrate Volunteer Day'
    },
    text1: {
      ru: 'День добровольца отмечается в России уже 4-й год. Участников ждут как традиционные мероприятия, так и активности в новых форматах. Деловая и культурно-развлекательная программы.',
      en: 'Volunteer Day is celebrated in Russia for the 4rd year. Participants will enjoy both traditional events and activities in new formats, as well as business and cultural entertainment programmes.'
    },
    subHead: {
      ru: 'Онлайн программа',
      en: 'On-line programme '
    },
    li1: {
      ru: 'Развлекательная программа (кинопоказы, квизы, квесты, кулинарные и ток-шоу, концерты и многое другое)',
      en: 'Entertainment program (film screenings, quizzes, quests, concerts, cooking and talk shows, etc.)'
    },
    li2: {
      ru: 'Выступления известных спикеров, обмен опытом, лекции, дискуссии и мастер-классы',
      en: 'Famous speakers performances, experience exchange , lectures, discussions and master classes.'
    },
    li3: {
      ru: 'Торжественная церемония вручения премии «Доброволец России - 2020»',
      en: 'The Russian Volunteer Award ceremony 2020. '
    },
    btn: {
      ru: 'Стать частью праздника',
      en: 'Become part of the holiday. '
    },

  },
  premia: {
    head: {
      ru: 'Вручение премии «Доброволец России – 2020»',
      en: 'Presentation of the award «Volunteer of Russia» 2020'
    },
    text1: {
      ru: 'Всероссийскому конкурсу волонтерских инициатив <b>«Доброволец России» 10 лет</b>',
      en: '«Volunteer of Russia» - All-Russian competition of volunteer initiatives – is 10 years.'
    },
    text2: {
      ru: '<b>Торжественное награждение победителей</b> конкурса состоится в Москве в рамках марафона <b>4 и 5 декабря</b>',
      en: 'The awarding ceremony for the winners of the competition takes place in Moscow as part of the marathon on December 4th and 5th.'
    },
    text3: {
      ru: '<b>Конкурс нацелен на выявление и поддержку лучших добровольческих инициатив</b>',
      en: 'The competition aims to identify and support the best volunteer initiatives.'
    },
    text4: {
      ru: '<b>Грантовый фонд: 90 000 000 рублей</b>',
      en: 'Grant Fund is RUB 90,000,000'
    },
    o1: {
      ru: 'заявок',
      en: 'applications'
    },
    o2: {
      ru: 'волонтеров',
      en: 'volunteers in project teams'
    },
    o3: {
      ru: 'победителей конкурса',
      en: 'competition winners'
    },
    o4: {
      ru: ' Участники Всероссийской акции #МЫВМЕСТЕ своими добровольческими поступками в период пандемии вдохновили на создание в рамках номинации «Доброе дело» Конкурса 5 специальных номинаций:',
      en: 'Participants of the All-Russian action #WEARETOGETHER - with their volunteer actions during a pandemic - inspired the creation of five special nominations within the framework of the «Good deed» nomination.'
    },
    li1: {
      ru: 'Волонтеры акции',
      en: 'Volunteers of the action'
    },
    li2: {
      ru: 'Организации некомерческого сектора, которые оказывали помощь в рамках акции',
      en: 'Non-profit sector organizations that provided assistance in the framework of the action'
    },
    li3: {
      ru: 'Коммерческие организации, которые оказывали помощь в рамках акции',
      en: 'Commercial organizations that provided assistance as part of the action'
    },
    li4: {
      ru: 'Граждане, оказавшие в рамках акции поддержку врачам',
      en: 'Citizens providing support to doctors as part of the action'
    },
    li5: {
      ru: 'Иностранные граждане, проживающие на территории РФ или в зарубежных странах, принимавшие активное участие в реализации акции #МЫВМЕСТЕ на территории России',
      en: 'Foreign citizens living in the Russian Federation or in foreign countries, who took an active part in the implementation of the action #WEARETOGETHER in Russia.'
    },
  },
  program: {
    head: {
      ru: 'Программа',
      en: 'Program'
    },
    day: {
      ru: 'День',
      en: 'Day'
    },
    add: {
      ru: 'Добавить в мой календарь',
      en: 'Add to my shedule'
    },
    speaker: {
      ru: 'Спикеры',
      en: 'Speakers'
    },
    description: {
      ru: 'Описание',
      en: 'Description'
    },
  },
  project: {
    head: {
      ru: 'Проекты',
      en: 'Project'
    },
  },
  proto: {
    head: {
      ru: 'Фото',
      en: 'Photo'
    },
  },
  Video: {
    head: {
      ru: 'Видео',
      en: 'Video'
    },
    more: {
      ru: 'Показать ещё видео',
      en: 'Show more video'
    },
  },
  Organizers : {
    head: {
      ru: 'Организаторы',
      en: 'Organizers '
    },
  },
  InfPartners : {
    head: {
      ru: 'Информационные партнеры',
      en: 'Information Partners '
    },
  },
  MediaPartners : {
    head: {
      ru: 'Медиапартнеры',
      en: 'Media partners '
    },
  },
  RecPartners : {
    head: {
      ru: 'Партнеры рекламной кампании',
      en: 'Advertising Campaign Partners '
    },
  },
  MarPartners : {
    head: {
      ru: 'Партнеры марафона',
      en: 'Marathon Partners '
    },
  },
  elka : {
    head: {
      ru: 'Исполните новогоднее желание тех, кому это особенно важно!',
      en: 'Make New Year wishes COME TRUE especially for those who need them!'
    },
    text: {
      ru: 'По традиции перед Новым годом проект «Мечтай со мной» организует Всероссийскую акцию «Ёлка желаний», где любой желающий сможет подарить частичку добра тем, кто каждый день борется за свою жизнь, живет в одиночестве или неблагополучных условиях, исполнив их желание.',
      en: 'The Dream with Me (Mechtai So Mnoi) project once again is organizing the all-Russian New Year Wishes Tree charitable campaign where everyone can share their kindness with those who are fighting for their lives, are lonely or facing hardships. Everyone can take part in the campaign and fulfill the wish of someone in need.'
    },
    footer: {
      ru: 'Регистрируйтесь на сайте елкажеланий.рф и становитесь волшебниками!',
      en: 'Sing up at елкажеланий.рф and become a guardian angel!'
    },
  },
  profile: {
    schedule: {
      ru: 'Моё расписание',
      en: 'My schedule',
    },
    logout: {
      ru: 'Выход',
      en: 'Log out',
    },
  },
  speakers: {
    head: {
      ru: 'Спикеры',
      en: 'Speakers',
    }
  },
  action: {
    head: {
      ru: 'Акция #МЫВМЕСТЕ',
      en: 'Action #WEARETOGETHER',
    },
    text: {
      ru: '#МЫВМЕСТЕ – акция взаимопомощи, объединившая страну в период пандемии COVID 19',
      en: '#WEARETOGETHER - a mutual aid campaign that united the country during the COVID 19 pandemic\n',
    },
    flex1: {
      ru: '<b>более 130 000</b> волонтеров',
      en: '<b> more than 130,000 </b> volunteers',
    },
    flex2: {
      ru: '<b>более 9,5 тыс</b>  партнеров',
      en: '<b> more than 9.5 thousand </b> partners',
    },
    flex3: {
      ru: '<b>более 4, 3 млн</b> человек получивших реальную помощь',
      en: '<b> more than 4, 3 million </b> people who received real help',
    },
    flex4: {
      ru: '<b>более 1, 8 млрд</b> рублей пожертвований',
      en: '<b> over 1, 8 billion </b> rubles of donations',
    },
    flex5: {
      ru: 'Сотрудники <b>496</b> организаций вносили пожертвования из своей зарплаты в фонд акции <b>#МЫВМЕСТЕ</b>',
      en: 'Employees of <b> 496 </b> organizations made donations from their salaries to the fund of the action <b> # WEVMESTE </b>',
    },
    btn: {
      ru: 'Подробнее об акции',
      en: 'More about the action',
    },
  },
  dobro: {
    text: {
      ru: 'DOBRO.RU –  единая информационная система развития волонтерства, дом проекта #МЫВМЕСТЕ  и экосистема IT-сервисов, помогающих развивать позитивную социальную активность',
      en: 'DOBRO.RU is a unified information system for the development of volunteering, the home of the #MYVMESTE project and an ecosystem of IT services that help develop positive social activity\n',
    },
    a1: {
      ru: 'ДОБРО.УНИВЕРСИТЕТ',
      en: 'DOBRO.UNIVERSITET',
    },
    t1: {
      ru: 'онлайн-университет социальных наук',
      en: 'online university of social sciences',
    },
    a2: {
      ru: 'ДОБРО.ЖУРНАЛ',
      en: 'DOBRO.JOURNAL',
    },
    t2: {
      ru: 'медиапроект о созидательной гражданской активности',
      en: 'media project about creative civic engagement\n',
    },
    a3: {
      ru: 'ДОБРО.ПОМОЩЬ',
      en: 'DOBRO.HELP',
    },
    t3: {
      ru: 'проект адресной помощи',
      en: 'targeted assistance project\n',
    },
    a4: {
      ru: 'ДОБРО.КОНКУРСЫ',
      en: 'DOBRO.COMPETITIONS',
    },
    t4: {
      ru: 'каталог конкурсов для волонтеров и организаторов волонтерской деятельности',
      en: 'catalog of competitions for volunteers and organizers of volunteer activities\n',
    },
    a5: {
      ru: 'ДОБРО.АНАЛИТИКА',
      en: 'DOBRO.ANALYTICS',
    },
    t5: {
      ru: 'открытые данные по развитию волонтерского движения в России.',
      en: 'open data on the development of the volunteer movement in Russia.\n',
    },
    head: {
      ru: ' Dobro.ru в цифрах',
      en: 'Dobro.ru in numbers\n',
    },
    f1: {
      ru: '<b>более 7 000 000</b> волонтерских часов',
      en: '<b> over 7,000,000 </b> volunteer hours',
    },
    f2: {
      ru: '<b>более 1,7 млн</b> волонтеров',
      en: '<b> over 1.7 million </b> volunteers',
    },
    f3: {
      ru: '<b>более 170 тыс.</b> вакансий для волонтеров',
      en: '<b> more than 170 thousand </b> vacancies for volunteers',
    },
    f4: {
      ru: '<b>более 25 тыс</b> организаторов',
      en: '<b> more than 25 thousand </b> organizers\n',
    },
    btn: {
      ru: 'Подробнее на dobro.ru',
      en: 'More details at dobro.ru',
    }
  },
  broadcast: {
    head: {
      ru: 'Трансляция',
      en: 'Broadcast'
    },
    program: {
      ru: 'Программа',
      en: 'Program'
    },
    comment: {
      ru: 'Оставьте комментарий',
      en: 'Leave a comment'
    },
    you: {
      ru: 'Вы',
      en: 'You'
    },
  }
}

export default translate