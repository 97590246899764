import React from 'react';
import Api from '../../service/Api'
import PanelTest from "../PanelTest";
import CourseStat from "../CourseStat";




class MyTesting extends React.Component {
    
    Api = new Api();

    state = {
        courses: {}
    }

    componentDidMount() {
        this.Api.getTestCourses()
            .then(this.getTestsSuccess)
            .catch(res => console.log(res))
    }

    getTestsSuccess = (courses) => {
        this.setState({courses})
    }

    _renderTest = () => {
        const {courses} = this.state;
        if (courses !== undefined && Object.values(courses).length) {
            return (
                Object.values(courses).map(course => {
                    let courseAttr = course.course.attributes
                    let testsAttr = course.course.tests
                    let pointTotal = 0
                    return (
                        <div key={courseAttr.created_at}>
                            <div className="content_head">
                                {courseAttr.name}
                            </div>
                            <div className="studyPanels">
                                {testsAttr !== undefined && Object.values(testsAttr).length &&
                                Object.values(testsAttr).map(test => {
                                    pointTotal = pointTotal + test.attributes.points;
                                    let lesson = {};
                                    lesson.attributes = {};
                                    lesson.attributes.id = test.attributes.lesson_id;
                                    lesson.MainHeader_header = test.attributes.name;
                                    lesson.MainHeader_subheader = test.attributes.description;
                                    lesson.plan = test.lessonPlan;
                                    return (
                                        <PanelTest
                                            key={test.attributes.id}
                                            active={test.active}
                                            name={test.attributes.name}
                                            text={test.attributes.description}
                                            steps={test.steps}
                                            userSteps={test.userSteps}
                                            id={test.attributes.id}
                                            balls={test.attributes.points}
                                            finished={test.finished}
                                            lesson={lesson}
                                            history={this.props.history}
                                        />
                                    )
                                })
                                }

                                <CourseStat courses={course.course} pointTotal={pointTotal}/>
                            </div>
                        </div>
                    )
                })
            )
        } else {
            return undefined;
        }
    }


    render () {
        return (
            <div>
                {this._renderTest()}
            </div>

        )
    }
}

export default MyTesting;
