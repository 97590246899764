import React from 'react'
import Api from 'service/Api'
import DobroTemplate from "./dobro";
import VmesteTemplate from "./vmeste";


export default class ForumDayEvents extends React.Component {
    Api = new Api()

    state = {
        user: undefined,
        actualForum: undefined,
        forumData: undefined,
        showAllProjects: false,
        session: false,
        msg: false,

    }


    componentDidMount() {
        this.getActualEvents();
        this.getUserData();
        this.getForumData();
        this.getMsg();
    }

    getActualEvents = () => {
        this.Api.getActualEvents(this.props.match.params.id)
          .then(actualForum => {
              this.setState({actualForum}, () => {
              })
          })
          .catch(e => console.log('error', e))
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => {
                this.setState({user}, () => {
                    this.Api.setSession()
                      .then(session => {this.setState({session})})
                })
            })
            .catch(e => console.log('error', e))
    }

    setShowAllProjects = (showAllProjects) => {
        this.setState({showAllProjects})
    }

    getForumData = () => {
        this.Api.getForum(this.props.match.params.id)
            .then(forumData => {
                this.setState({forumData}, () => {
                })
            })
            .catch(e => console.log('error', e))
    }

    getMsg = () => {
        this.Api.getMsg(844)
          .then(msg => this.setState({msg}))
    }

    render() {
        const {user, forumData, actualForum, showAllProjects, session, msg} = this.state;
        const forumId = this.props.match.params.id
        if(forumId == 6) {
            return <VmesteTemplate
                    user={user}
                    actualForum={actualForum}
                    forumData={forumData}
                    showAllProjects={showAllProjects}
                    forumId={forumId}
                    getUserData={this.getUserData}
                    getForumData={this.getForumData}
                    setShowAllProjects={this.setShowAllProjects}
                    session={session}
                    msg={msg}
                />

        }else{
            return <DobroTemplate
                user={user}
                forumData={forumData}
                showAllProjects={showAllProjects}
                forumId={forumId}
                getUserData={this.getUserData}
                getForumData={this.getForumData}
                setShowAllProjects={this.setShowAllProjects}
            />
        }
    }
}
