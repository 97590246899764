import React, {useState} from "react";

const El = ({partner}) => {
  const [show, setShow] = useState(false)
  return (
    <div className="partner_el">
      <div className="partner_el-desc">
        <div className="name">{partner.name}</div>
        {show &&
        <div className="description"
             dangerouslySetInnerHTML={{__html: partner.description}}/>
        }
      </div>
      <div onClick={() => {setShow(!show)}} className={"icon " + (show && 'minus')}/>
    </div>
  )
}

export default El