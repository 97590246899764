import React from "react";

const youTubeGetId = (url) => {
    let expression = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be[.]?\/|youtube\.com[.]?\/(?:embed\/|v\/|watch\/?\?(?:\S+=\S*&)*v=))([\w-]{11})\S*$/;
    return url.match(expression) ? RegExp.$1 : undefined;
}

const LessonTypeVideo = ({content}) => {
    console.log(youTubeGetId(content.url))
    return (
        <div>
            <div className={'step__text'} dangerouslySetInnerHTML={{__html: content.description}}/>

            {youTubeGetId(content.url) ?
                <iframe
                    className={'step__video'}
                    src={"https://www.youtube.com/embed/" + youTubeGetId(content.url)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen/>
                :
                <video
                    className={'step__video'}
                    controls="controls"
                    poster={"/video1/lesson/" + content.url + '.png'}
                    autoplay={"autoplay"}
                >
                    <source src={"/video1/lesson/" + content.url + '.ogv'} type='video/ogg; codecs="theora, vorbis"'/>
                    <source src={"/video1/lesson/" + content.url + '.mp4'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
                    <source src={"/video1/lesson/" + content.url + '.webm'} type='video/webm; codecs="vp8, vorbis"'/>
                    Тег video не поддерживается вашим браузером.
                    <a href="video/duel.mp4">Скачайте видео</a>.
                </video>
            }

        </div>
    )
}

export default LessonTypeVideo