import React, {useState, useEffect} from "react";
import Header from "../../pageComponents/header-vmeste";
import Banner from "../../pageComponents/banner";
import bg from "../../assets/image/vmesteBg.png";
import bgPeople from "../../assets/image/forumDayEventsPeople.svg";
import Speaker from "../../pageComponents/speaker";
import Programs from "../../pageComponents/programs";
import Organizers from "../../pageComponents/organizers";
import Partners from "../../pageComponents/partners";
import Projects from "../../pageComponents/projects/vmeste";
import Button from "../../components/button";
import Footer from "../../pageComponents/footer";
import Slider from "react-slick";

import am1 from '../../assets/image/vmeste/24.png'
import am2 from '../../assets/image/vmeste/map.png'
import am3 from '../../assets/image/vmeste/Globus.png'
import am4 from '../../assets/image/vmeste/Star.png'
import howCountry__banner from '../../assets/image/vmeste/howCountry__banner.png'
import ai1 from '../../assets/image/vmeste/tort.png'
import ai2 from '../../assets/image/vmeste/medal.png'
import ai3 from '../../assets/image/vmeste/peoples.png'
import ai4 from '../../assets/image/vmeste/cache.png'
import photoSlide from '../../assets/image/vmeste/photoSlide.png'
import actionImg from '../../assets/image/vmeste/action__img.png'
import dobroBg from '../../assets/image/vmeste/dobro_bg.png'

import o1 from '../../assets/image/vmeste/org1.png'
import o2 from '../../assets/image/vmeste/org2.png'
import o3 from '../../assets/image/vmeste/org3.png'
import o4 from '../../assets/image/vmeste/org4.png'
import o5 from '../../assets/image/vmeste/org5.png'

import part from '../../assets/image/vmeste/part.png'
import mpart from '../../assets/image/vmeste/mpart.png'
import rpart from '../../assets/image/vmeste/rpart.png'

import mp1 from '../../assets/image/vmeste/marPart/1.png'
import mp2 from '../../assets/image/vmeste/marPart/2.png'
import mp3 from '../../assets/image/vmeste/marPart/3.png'
import mp4 from '../../assets/image/vmeste/marPart/4.png'
import mp5 from '../../assets/image/vmeste/marPart/5.png'
import mp6 from '../../assets/image/vmeste/marPart/6.png'
import mp7 from '../../assets/image/vmeste/marPart/7.png'
import mp8 from '../../assets/image/vmeste/marPart/8.png'
import mp9 from '../../assets/image/vmeste/marPart/9.png'
import mp10 from '../../assets/image/vmeste/marPart/10.png'
import mp11 from '../../assets/image/vmeste/marPart/11.png'
import mp12 from '../../assets/image/vmeste/marPart/12.png'
import mp13 from '../../assets/image/vmeste/marPart/13.png'
import mp14 from '../../assets/image/vmeste/marPart/14.png'
import mp15 from '../../assets/image/vmeste/marPart/15.png'
import mp17 from '../../assets/image/vmeste/marPart/17.png'
import mp18 from '../../assets/image/vmeste/marPart/18.png'
import mp19 from '../../assets/image/vmeste/marPart/19.png'
import lbl from '../../assets/image/vmeste/marPart/lbl.png'
import logo_rossiya from '../../assets/image/vmeste/marPart/logo-rossiya-24.png'
import mail from '../../assets/image/vmeste/marPart/mail.png'
import send from '../../assets/image/vmeste/send.png'
import translate from "./_translate";
import {useCookies} from "react-cookie";
import moment from "moment";
import ProgramList from "./_program__list";

let chat;

const VmesteTemplate = ({user, forumData, actualForum, showAllProjects, forumId, getUserData, getForumData, setShowAllProjects, session, msg}) => {
  const [cookies] = useCookies(['lang']);
  const [showVideo, setShowVideo] = useState(false)
  const [msgShow, setMsgShow] = useState(false)
  const [message, setMessage] = useState('')
  const [showEvent, setShowEvent] = useState(false)
  const [messages, setMessages] = useState([])
  const [className, setClassName] = useState({})
  let lang = cookies.lang || 'ru'
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  useEffect(() => {
    if (session) {
      console.log('forumData', forumData)
      let websocketServerLocation = 'wss://dobroinrussia.online/chatonline'; //название экшена должен совпадать с route в запускаемом через консоль экшене
      console.log('msg',msg)
      let res = {};
      if(!msgShow) {
        if (msg && msg.length) {
          msg.reverse();
          msg.map(ms => {
            console.log(ms)
            addMsg(ms)
          })
          setMsgShow(true)
        }
      }
      if(user) {
        startChat(websocketServerLocation, session)
      }
      forumData && forumData.forumDays &&
      forumData.forumDays.map(day => {
        forumData.forumDaysEvent[day.id].map(event => {
          if (event.hidden == 0) {
            getClassList(day, event, forumData.server.date)
          }
        })
      })
    }
  }, [session, msg, forumData])

  const getClassList = (day, event, serverTime) => {
    let serverTimeMoment = moment(serverTime);
    let startVideoMomemnt = moment(day.date + ' ' + event.hour_start).subtract('30', 'minutes');
    let startMomemnt = moment(day.date + ' ' + event.hour_start);
    let EndMomemnt = moment(day.date + ' ' + event.hour_end);
    let diffVideo = serverTimeMoment.diff(startVideoMomemnt)
    let diffStart = serverTimeMoment.diff(startMomemnt)
    console.log(serverTimeMoment, startMomemnt)
    let diffEnd = serverTimeMoment.diff(EndMomemnt)
    let list_el_class = ''
    if (diffStart > 0 && diffEnd > 0) {
      list_el_class = 'list_el-end'
    } else if (diffStart > 0 && diffEnd < 0) {
      list_el_class = 'list_el-now'
    } else {
      list_el_class = 'list_el-next'

      // if (event.track === 1) {
      //     list_el_class = 'list_el-red'
      // }
      // if (event.track === 2) {
      //     list_el_class = 'list_el-blue'
      // }
      // if (event.track === 3) {
      //     list_el_class = 'list_el-green'
      // }
    }
    className[Object.keys(className).length] = list_el_class
    setClassName(className)
  }

  let chat_id = 844
  const startChat = (websocketServerLocation, sessId, chats) => {
    chat = new WebSocket(websocketServerLocation);
    chat.onopen = function (e) {
      chat.send(JSON.stringify({
        'action': 'register',
        'session_id': session,
        'chats': [chat_id],
      }));
      console.info("Вы онлайн");
      // $('#response').text("Вы онлайн");
    };
    chat.onerror = function (e) {
      // Закрываем соединение при ошибке и сообщаем об этом пользователю
      console.info("Возникла ошибка.");
      chat.close();
    };
    chat.onclose = function () {
      // Если сервер перезагрузили или пропал интернет, переподключаемся при закрытии соединения
      console.info("Попытка подключения...");
      setTimeout(() => {
        // startChat(websocketServerLocation, sessId)
      }, 5000);
    };
    chat.onmessage = function (e) {

      var response = JSON.parse(e.data);

      console.log('res ', response)

      addMsg(response)
      
    };
  }

  function sendModer(mid) {
    console.log('mid', mid)
    chat.send(
      JSON.stringify({
        'action': 'moderate',
        'mid': mid,
      })
    )
  }

  const addMsg = (response) => {
    let msg = document.createElement('div')
    msg.className = response.self ? 'message out' : 'message in'
    let name = document.createElement('div')
    name.className = 'name';
    name.append(response.self ? translate.broadcast.you[lang] : response.name)
    let text = document.createElement('div')
    text.className = 'text'
    text.append(response.message)
    msg.append(name)
    msg.append(text)
    if(response.m){
      let btn = document.createElement('button')
      btn.className = 'message__moderate'
      btn.setAttribute('mid', response.m)
      btn.onclick = function (){
        sendModer(btn.getAttribute('mid'))
        btn.hidden = true
      }
      btn.append('Показать')
      msg.append(btn)
    }


    document.getElementById('dialogs').prepend(msg)
  }

  const sendMsg = () => {
    let message_send = JSON.stringify({
      'action': 'message',
      'message': message,
      'cid': chat_id,
      'to': 'false',
    });
    // if (chat.readyState === chat.OPEN) {
      // Если соедение открыто
      chat.send(message_send);
    // } else {
    //   console.warn('WS NOT CONNECTED');
    // }
    setMessage('')
    setShowEvent(true)
    setTimeout(() => {
      setShowEvent(false)
    }, 3000)

    

  }

  let i = 0

  return (
    <div className="forumDatEvents__page page">
      <Header
        className={'header__orange'}
        user={user}
        getUserData={getUserData}
        translate={translate}
        lang={lang}
      />
      <Banner
        style={{paddingTop: 96}}
        className={'bvmeste'}
        bg={bg}
        // banner__date_start={forumData.forum.date_start}
        // banner__date_end={forumData.forum.date_end}
        user={user}
        translate={translate}
        lang={lang}
      />

      <div className="aboutMarathon">
        <div className="cnt">
          <div className="aboutMarathon__head">
            {/*О марафоне*/}
            {translate.about.head[lang]}
          </div>
          <div className="marathons">
            <div className="Marathon__el">
              <div className="icon">
                <img src={am1} alt=""/>
              </div>
              <span dangerouslySetInnerHTML={{__html: translate.about.text1[lang]}}/>
            </div>
            <div className="Marathon__el">
              <div className="icon">
                <img src={am2} alt=""/>
              </div>
              <span dangerouslySetInnerHTML={{__html: translate.about.text2[lang]}}/>
            </div>
            <div className="Marathon__el">
              <div className="icon">
                <img src={am3} alt=""/>
              </div>
              <span dangerouslySetInnerHTML={{__html: translate.about.text3[lang]}}/>
            </div>
            <div className="Marathon__el">
              <div className="icon">
                <img src={am4} alt=""/>
              </div>
              <span dangerouslySetInnerHTML={{__html: translate.about.text4[lang]}}/>
            </div>
          </div>
          <a href="#programs" className="aboutMarathon__btn">{translate.about.btn[lang]}</a>
        </div>
      </div>

      {forumData && forumData.forumSpeaker &&
      <Speaker
        className={'vmesteSpeaker'}
        speakers={forumData.forumSpeaker}
        translate={translate}
        lang={lang}
      />
      }

      <div className="howCountry">
        <div className="cnt">
          <div className="howCountry__head">
            {translate.country.head[lang]}
          </div>
          <div className="howCountry__banner">
            <img src={howCountry__banner} alt="" className="howCountry__icon"/>
            <div className="desc">
              {translate.country.text1[lang]}

            </div>
            <div className="list">
              <div className="list__head">
                {translate.country.subHead[lang]}
              </div>
              <div className="list__el">
                {translate.country.li1[lang]}
              </div>
              <div className="list__el">
                {translate.country.li2[lang]}
              </div>
              <div className="list__el">
                {translate.country.li3[lang]}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="broadcast">
        <div className="broadcast__bg"/>
        <div className="cnt">
          <div className="broadcast__head">{translate.broadcast.head[lang]}</div>
          <div className="broadcast__video">
            <iframe
              src={
                lang === 'ru'
                ? '//vk.com/video_ext.php?oid=-98959944&id=456239395&hash=1fd368643899367a'
                : '//vk.com/video_ext.php?oid=-98959944&id=456239403&hash=ca50a516dc90764b'
              }
              width="100%"
              height="100%"
              frameBorder="0" allowFullScreen/>
            <div className="broadcast__program">
              <div className="program__head">
                {translate.broadcast.program[lang]}
              </div>
              {actualForum &&
                <>
                <ProgramList actualForum={actualForum}/>
                </>
              }
            </div>
          </div>


          {user &&
          <div className="broadcast__chat">
            <div className="massage__input">
              <input
                type="text"
                value={message}
                placeholder={translate.broadcast.comment[lang]}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={event => {
                  if(event.key == 'Enter'){
                    sendMsg()
                  }
                }}
              />
              <button
                onClick={sendMsg}
                type="button"
              >
                <img src={send} alt=""/>
              </button>
              {showEvent &&
              <div className="event">
                Ваше сообщение скоро появится в чате
              </div>
              }
            </div>
            <div className="dialogs" id="dialogs">
            </div>
          </div>
          }
        </div>
      </div>


      <div className="awarding">
        <div className="cnt">
          <div className="awarding__head">
            {translate.premia.head[lang]}
          </div>
          <div className="awarding__row">
            <div className="row__left w100">
              <div className="row__el">
                <div className="icon">
                  <img src={ai1} alt=""/>
                </div>
                <span dangerouslySetInnerHTML={{__html: translate.premia.text1[lang]}}/>
              </div>
              <div className="row__el">
                <div className="icon">
                  <img src={ai2} alt=""/>
                </div>
                <span dangerouslySetInnerHTML={{__html: translate.premia.text2[lang]}}/>

              </div>
              <div className="row__el">
                <div className="icon">
                  <img src={ai3} alt=""/>
                </div>

                <span dangerouslySetInnerHTML={{__html: translate.premia.text3[lang]}}/>

              </div>
              <div className="row__el">
                <div className="icon">
                  <img src={ai4} alt=""/>
                </div>

                <span dangerouslySetInnerHTML={{__html: translate.premia.text4[lang]}}/>

              </div>
            </div>
            <div className="row__right fs">
              <div className="orange">
                <b>53 219</b> {translate.premia.o1[lang]}
                <br/>
                <br/>
                <b>3 248 915</b> {translate.premia.o2[lang]}
                <br/>
                <br/>
                <b>125</b> {translate.premia.o3[lang]}
              </div>
            </div>
          </div>
          <div className="awarding__row">
            <div className="row__left fs">
              <div className="orange">
                {translate.premia.o4[lang]}
              </div>
            </div>
            <div className="row__right w100">
              <div className="li">{translate.premia.li1[lang]}</div>
              <div className="li">{translate.premia.li2[lang]}</div>
              <div className="li">{translate.premia.li3[lang]}</div>
              <div className="li">{translate.premia.li4[lang]}</div>
              <div className="li">{translate.premia.li5[lang]}</div>
            </div>
          </div>
        </div>
      </div>


      {forumData && forumData.forumDays &&
      <Programs
        className={'programmVmeste'}
        forum_id={forumId}
        user={user}
        serverTime={forumData.server.date}
        timeZone={forumData.forum.timeZone}
        forumDays={forumData.forumDays}
        forumDaysEvent={forumData.forumDaysEvent}
        forumDaysEventSpeakers={forumData.forumDaysEventSpeakers}
        userEvents={forumData.userEvents}
        getForumData={getForumData}
        translate={translate}
        lang={lang}
      />
      }

      <div className="cnt">
        <iframe
          className='questionnaire__iframe'
          src="https://dobroinrussia.online/qeuestionnaire/6"
          frameBorder="0"
          width="100%"
          // onload={(o) => {o.style.height=o.contentWindow.document.body.scrollHeight+"px";}}
        />
      </div>

      <div className="action">
        <div className="cnt">
          <div className="action__head">
            {translate.action.head[lang]}
          </div>
          <div className="action__text">
            <span>
            {translate.action.text[lang]}
            </span>
            <img src={actionImg} alt=""/>
          </div>
          <div className="flex">
            <div className="flex__el">
              <div className="flex__row" dangerouslySetInnerHTML={{__html: translate.action.flex1[lang]}}/>
              <div className="flex__row" dangerouslySetInnerHTML={{__html: translate.action.flex2[lang]}}/>
            </div>
            <div className="flex__el">
              <div className="flex__row" dangerouslySetInnerHTML={{__html: translate.action.flex3[lang]}}/>
              <div className="flex__row" dangerouslySetInnerHTML={{__html: translate.action.flex4[lang]}}/>
            </div>

            <div className="flex__el">
              <div className="flex__row" dangerouslySetInnerHTML={{__html: translate.action.flex5[lang]}}/>
            </div>
          </div>
          <a href="https://xn--2020-f4dsa7cb5cl7h.xn--p1ai/" target="blank" className="action__btn">
            {translate.action.btn[lang]}
          </a>
        </div>
      </div>
      <div className="dobro">
        <div className="cnt">
          <div className="dobro__text">
            {translate.dobro.text[lang]}
          </div>
          <div className="dobro__block">
            <div className="dobro__row">
              <a href="https://edu.dobro.ru/" target="blank">{translate.dobro.a1[lang]}</a>
              <span>{translate.dobro.t1[lang]}</span>
            </div>
            <div className="dobro__row">
              <a href="https://journal.dobro.ru/ " target="blank">{translate.dobro.a2[lang]}</a>
              <span>{translate.dobro.t2[lang]}</span>
            </div>
            <div className="dobro__row">
              <a href="https://help.dobro.ru/" target="blank">{translate.dobro.a3[lang]}</a>
              <span>{translate.dobro.t3[lang]}</span>
            </div>
            <div className="dobro__row">
              <a href="https://contests.dobro.ru/" target="blank">{translate.dobro.a4[lang]}</a>
              <span>{translate.dobro.t4[lang]}</span>
            </div>
            <div className="dobro__row">
              <a href="https://dobro.ru/analytics " target="blank">{translate.dobro.a5[lang]}</a>
              <span>{translate.dobro.t5[lang]}</span>
            </div>
          </div>
          <div className="dobro__head">
            Dobro.ru в цифрах
          </div>
          <div className="flex">
            <div className="flex__el">
              <div className="icon"/>
              <span dangerouslySetInnerHTML={{__html: translate.dobro.f1[lang]}}/>
            </div>
            <div className="flex__el">
              <div className="icon"/>
              <span dangerouslySetInnerHTML={{__html: translate.dobro.f2[lang]}}/>
            </div>
            <div className="flex__el">
              <div className="icon"/>
              <span dangerouslySetInnerHTML={{__html: translate.dobro.f3[lang]}}/>
            </div>
            <div className="flex__el">
              <div className="icon"/>
              <span dangerouslySetInnerHTML={{__html: translate.dobro.f4[lang]}}/>
            </div>
          </div>
          <a href="https://dobro.ru" target="blank" className="dobro__btn">
            {translate.dobro.btn[lang]}
          </a>

        </div>
      </div>
      <div className={"projects__all " + (showAllProjects && 'show')}>
        <Projects
          showDesc={'false'}
          forum_id={forumId}
          translate={translate}
          lang={lang}
        />
      </div>
      <div className="photos">
        <div className="cnt">
          <div className="photos__head">
            {translate.proto.head[lang]}
          </div>
          <Slider {...settings}>
            <img src={photoSlide} alt=""/>
            <img src={'https://thumb.tildacdn.com/tild3763-6439-4164-a137-346261613765/-/format/webp/VDM_2_132.jpg'}
                 alt=""/>
            <img src={'https://thumb.tildacdn.com/tild3665-3733-4361-b230-356431346234/-/format/webp/VDM_2_101.jpg'}
                 alt=""/>
            <img src={'https://thumb.tildacdn.com/tild6663-3734-4232-b830-623961633233/-/format/webp/VDM_2_145.jpg'}
                 alt=""/>
          </Slider>
        </div>
      </div>
      <div className="video">
        <div className="cnt">
          <div className="video__head">
            {translate.Video.head[lang]}
          </div>
          <div className="videos">
            <iframe width="360" height="200" src="https://www.youtube.com/embed/gvQI8XGT_lk" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            <iframe width="360" height="200" src="https://www.youtube.com/embed/tqvWgwLsRKY" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            <iframe width="360" height="200" src="https://www.youtube.com/embed/QczVYfEQtdk" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            <iframe width="360" height="200" src="https://www.youtube.com/embed/5h7HHpBXstY" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            <iframe width="360" height="200" src="https://www.youtube.com/embed/FuqsZxdkWUE" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            <iframe width="360" height="200" src="https://www.youtube.com/embed/PM8ht4BuzhY" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
            {showVideo &&
            <>
              <iframe width="360" height="200" src="https://www.youtube.com/embed/XcbDHJMIRW0" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
              <iframe width="360" height="200" src="https://www.youtube.com/embed/VevUGqfKqyY" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
              <iframe width="360" height="200" src="https://www.youtube.com/embed/gG7o_kr7_0A" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
              <iframe width="360" height="200" src="https://www.youtube.com/embed/jc5z0Ys_hu8" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </>
            }
          </div>
          {!showVideo &&
          <button type="button" className="video__btn" onClick={() => {
            setShowVideo(true)
          }}>
            {translate.Video.more[lang]}
          </button>
          }
        </div>
      </div>
      <div className="org">
        <div className="cnt">
          <div className="org__head">
            {translate.Organizers.head[lang]}
          </div>
          <div className="orgs">
            <a target='blank' href="https://fadm.gov.ru/" className="org__el"><img
              src='https://static.tildacdn.com/tild6565-6532-4239-b764-643865303463/rosmolodezh-orange.svg' alt=""/></a>
            <a target='blank' href="https://rospatriotcentr.ru/" className="org__el"><img
              src='https://thumb.tildacdn.com/tild6365-6661-4464-a662-353131633432/-/resize/130x/-/format/webp/photo.png'
              alt=""/></a>
            <a target='blank' href="https://xn--80ae4d.xn--p1ai/ " className="org__el"><img
              src='https://thumb.tildacdn.com/tild3564-3064-4262-a233-336131343333/-/resize/190x/-/format/webp/main_logo-png.png'
              alt=""/></a>
            <a target='blank' href="https://contests.dobro.ru/dr" className="org__el"><img
              src='https://thumb.tildacdn.com/tild3563-3561-4038-b730-306231346332/-/resize/100x/-/format/webp/AVC_Konk_Logo20.png'
              alt=""/></a>
            <a target='blank' href="https://xn----ctbhcbtapdmikb4a2a0m.xn--p1ai/ " className="org__el"><img
              src='https://thumb.tildacdn.com/tild3662-3537-4566-b636-346631386362/-/resize/180x/-/format/webp/-.png'
              alt=""/></a>
          </div>
        </div>
      </div>
      <div className="part">
        <div className="cnt">
          <div className="part__head">
            {translate.InfPartners.head[lang]}
          </div>
          <div className="parts">
            <a target='blank' href="https://www.gazprom-media.com/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild3736-6332-4837-b665-326133336539/-/resize/200x/-/format/webp/_.png"
              alt=""/></a>
            <a target='blank' href="https://nm-g.ru/holding/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild3339-6466-4237-a461-333033326264/-/resize/140x/-/format/webp/photo.png"
              alt=""/></a>
            <a target='blank' href="https://www.1tv.ru/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild6133-3031-4061-b333-386136343330/-/resize/180x/-/format/webp/_.jpg"
              alt=""/></a>
            <a target='blank' href="https://russianmediagroup.ru/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild6536-6136-4136-b933-613563623733/-/resize/170x/-/format/webp/photo.jpg"
              alt=""/></a>
            <a target='blank' href="https://ria.ru/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild6236-3964-4530-b562-343339386238/-/resize/180x/-/format/webp/photo.jpg"
              alt=""/></a>
          </div>
        </div>
      </div>
      <div className="part">
        <div className="cnt">
          <div className="part__head">
            {translate.MediaPartners.head[lang]}
          </div>
          <div className="parts">
            <a target='blank' href="https://mail.ru/ " className="part__el"><img
              src={mail}
              alt=""/></a>
            {/*<a target='blank' href="https://vk.com " className="part__el"><img*/}
            {/*  src="https://thumb.tildacdn.com/tild6365-3934-4333-b938-623664386166/-/resize/70x/-/format/webp/VK_Compact_Logo.png"*/}
            {/*  alt=""/></a>*/}
            <a target='blank' href="https://www.tiktok.com " className="part__el"><img
              src="https://thumb.tildacdn.com/tild6635-6463-4539-b439-336162346632/-/resize/230x/-/format/webp/TikTok-logo-CMYK_hor.png"
              alt=""/></a>
            <a target='blank' href="https://vk.com/likeinrussia " className="part__el"><img
              src="https://thumb.tildacdn.com/tild6531-3633-4337-a635-396631343135/-/resize/200x/-/format/webp/78698ad28329cb6bab5e.png"
              alt=""/></a>
            <a target='blank' href="https://live.russia.tv/ " className="part__el"><img
              src={logo_rossiya}
              style={{height: 60}}
              alt=""/></a>
          </div>
        </div>
      </div>
      <div className="part">
        <div className="cnt">
          <div className="part__head">
            {translate.RecPartners.head[lang]}
          </div>
          <div className="parts">
            <a target='blank' href="https://maergroup.ru/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild6161-3139-4066-a234-343435646534/-/resize/230x/-/format/webp/MAER__.png"
              alt=""/></a>
            <a target='blank' href="http://www.gallerymedia.com/ " className="part__el"><img
              src="https://thumb.tildacdn.com/tild3662-3366-4434-a137-313638613734/-/resize/180x/-/format/webp/Gallery_Logo.png"
              alt=""/></a>
            <a target='blank' href="https://www.hyundai.ru/" className="part__el"><img src={mp18} alt=""/></a>
            <a target='blank' href="https://www.svo.aero/ru/main" className="part__el"><img src={mp19} alt="" style={{height: 40}}/></a>
            <a target='blank' href="https://lbl.ru/" className="part__el"><img src={lbl} alt=""/></a>
          </div>
        </div>
      </div>
      <div className="part">
        <div className="cnt">
          <div className="part__head">
            {translate.MarPartners.head[lang]}
          </div>
          <div className="parts">
            <a target='blank' href="https://rosatom.ru/ " className="part__el"><img src={mp1} alt=""/></a>
            <a target='blank' href="https://www.kfc.ru/ " className="part__el"><img src={mp2} alt=""/></a>
            <a target='blank' href="http://www.artscienceandsport.com/  " className="part__el"><img src={mp3}
                                                                                                    alt=""/></a>
            <a target='blank' href="https://pamyatpokoleniy.ru/   " className="part__el"><img src={mp4} alt=""/></a>
            <a target='blank' href="https://rsv.ru/marketing/index.html " className="part__el"><img src={mp5}
                                                                                                    alt=""/></a>
            <a target='blank' href="https://onf.ru/" className="part__el"><img src={mp6} alt=""/></a>
            <a target='blank' href="https://bolshayaperemena.online/  " className="part__el"><img src={mp7} alt=""/></a>
            <a target='blank' href="https://contests.dobro.ru/md  " className="part__el"><img src={mp10} alt=""/></a>
            <a target='blank' href="https://www.mos.ru/kos/ " className="part__el"><img src={mp11} alt=""/></a>
            <a target='blank' href="https://futureteam.world/ " className="part__el"><img src={mp12} alt=""/></a>
            <a target='blank' href="https://xn--b1agpccjfbucrelc9ign.xn--p1ai/ " className="part__el"><img src={mp13}
                                                                                                           alt=""/></a>
            <a target='blank' href="https://tavrida.art/ " className="part__el"><img src={mp14} alt=""/></a>
            <a target='blank' href="https://www.oprf.ru/ " className="part__el"><img src={mp15} alt=""/></a>
            <a target='blank' href="https://www.donorsforum.ru/  " className="part__el"><img
              src='https://thumb.tildacdn.com/tild3837-6361-4038-b762-313831376365/-/resize/150x/-/format/webp/_.png'
              alt=""/></a>
            <a target='blank' href="https://black-star.ru/ " className="part__el"><img src={mp8} alt=""/></a>
            <a target='blank' href="https://emprana.ru/ " className="part__el"><img src={mp9} alt=""/></a>
            <a target='blank' href="https://xn--80aapampemcchfmo7a3c9ehj.xn--p1ai/" className="part__el"><img src={mp17}
                                                                                                              alt=""/></a>

          </div>
        </div>
      </div>
      <Footer className={'fVmeste'}/>
    </div>
  )
}

export default VmesteTemplate