import React from "react";
import Api from "../../service/Api";
import { Link } from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";

class Account extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    Api = new Api();


    render() {
        const {user} = this.props
        return (
            <div className="header__account">
                {user !== undefined &&
                (user === false ?
                        <a href={'https://dobroinrussia.online/site/login'}
                           className="header__account-link">
                            <span className="header__account-text">Войти</span>
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="white"/>
                                <path d="M28 27V25C28 22.7909 26.2091 21 24 21H16C13.7909 21 12 22.7909 12 25V27" stroke="#FF6700" strokeWidth="2"/>
                                <circle cx="20" cy="13" r="4" stroke="#FF6700" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </a>
                        :
                        <Link to={"/" + (this.props.allCookies.lang || 'ru') + '/profile'}
                            className="header__account-link">
                            <span className="header__account-text">
                                    {user.name} {user.surname} <br/>
                            </span>

                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="20" cy="20" r="20" fill="white"/>
                                <path d="M28 27V25C28 22.7909 26.2091 21 24 21H16C13.7909 21 12 22.7909 12 25V27" stroke="#FF6700" strokeWidth="2"/>
                                <circle cx="20" cy="13" r="4" stroke="#FF6700" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                        </Link>
                )
                }
            </div>

        )
    }
}

export default withCookies(Account)