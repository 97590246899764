import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import Main from './navigation/lang';
import 'assets/sass/main.sass'
import ChatBot from "./components/chatBot";
import {useCookies, CookiesProvider} from 'react-cookie';


const App = () => {
    const [cookies, setCookie] = useCookies(['lang']);

    useEffect(() => {
        lang()
    }, [])

    const lang = () => {
        const supportedLanguages = ["en", "ru"];
        let lang = window.location.pathname.split('/')[1];
        if (!cookies.lang) {
            setCookie('lang', 'ru', {path: '/'})
        }
        console.log(lang, cookies.lang, supportedLanguages.indexOf(lang) !== -1)
        if (lang !== cookies.lang && supportedLanguages.indexOf(lang) !== -1) {
            setCookie('lang', lang, {path: '/'})
        }
        // console.log(lang, supportedLanguages.indexOf(lang))
        // if (!lang || supportedLanguages.indexOf(lang) === -1){
        //     document.location.href = window.location.origin + '/ru' + window.location.pathname
        // }else{
        //     if(lang !== cookies.lang) {
        //         setCookie('lang', lang)
        //     }
        // }
    }

    return (
        <div className="wrp">
            <CookiesProvider>
                <Main/>
            </CookiesProvider>
            {/*<ChatBot/>*/}
        </div>
    )
}


// class App extends React.Component {
//
//
//     componentDidMount() {
//         this.lang()
//     }
//
//
//     componentDidUpdate(prevProps) {
//         if (this.props.location !== prevProps.location) {
//             window.scrollTo(0, 0)
//         }
//         this.lang()
//
//     }
//
//
//
//     render() {
//         return (
//             <div className="wrp">
//                 <CookiesProvider>
//                     <Main/>
//                 </CookiesProvider>
//                 <ChatBot/>
//             </div>
//         )
//     }
// }

export default withRouter(App);

