import React from "react";

const LessonInteractiveVideo = ({attributes, user}) => {
    return (
        <div>
            <iframe
                className='step__video'
                name={Date.now()}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                src={`https://rp-study.dg-prod.su/streamer-lesson/${attributes.id}/${user.id}?v=12`}
                frameborder="0"/>
        </div>
    )
}

export default LessonInteractiveVideo