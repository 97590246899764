import React from "react";
import img from 'assets/image/speaker_el.png'
import Slider from "react-slick";
import translate from "../../page/forumDayEvents/_translate";
import Programs from "../programs";

export default ({className, speakers, translate, lang}) => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true

        }
      }
    ]
  };

  const SLIDER_ROWS = 2
  const _renderSlideColumn = (speakers) => {
    const html = []
    for (let i = 0; i <= speakers.length - 1; i++) {
      if (i % SLIDER_ROWS >= 1 && i % SLIDER_ROWS <= SLIDER_ROWS - 1) continue
      html.push(
        <div key={'teamsSlider__column' + i} className='teamsSlider__column'>
          {speakers.slice(i, i + SLIDER_ROWS).map((speaker, index) => {
              return (
                <div key={speaker.id + +new Date()}
                     className="speaker__el">
                  <div className="img">
                    <img src={'https://dobroinrussia.online/uploads' + (speaker.photo || '/speakers/ava.png')} alt=""/>
                  </div>

                  <div className="name">{speaker.surname} {speaker.name} {speaker.patronymic}</div>
                  <div className="text">
                    {speaker.description}
                  </div>
                </div>
              )
          })}
        </div>
      )
    }
    return html
  }

  return (
        <div className={"speaker " + (className)}>
            <div className="cnt">
                {/*<div className="speaker__head">
                  {translate.speakers.head[lang]}
                </div>*/}
                  <Slider {...settings}>
                    {speakers &&
                      _renderSlideColumn(Object.values(speakers))
                    }
                  </Slider>
            </div>
        </div>
    )
}