import React from "react";
import { Link } from 'react-router-dom'
import img from 'assets/image/forumBanerImg.png'
import {useCookies} from "react-cookie";

export default ({user}) => {
    const [cookies] = useCookies(['lang']);

    return (
        <div className='forumBanner'>
            <div className="cnt">
                <Link to={"/" + (cookies.lang || 'ru') + '/forums'} className={'forumBanner__link'}>
                    <img src={img} alt="" className={'forumBanner__img'}/>
                </Link>
                <div className="forumBanner__foot">
                    <div className={'forumBanner__foot-left'}>
                        <div className="forumBanner__date">29 августа - 27 сентября</div>
                        <div className="forumBanner__text">Окружные форумы добровольцев 2020</div>
                    </div>
                    {user === false &&
                    <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}
                       className={'button button__yellow button__big'}
                    >Регистрация</a>
                    }
                </div>
            </div>
        </div>
    )
}