import React from "react";
import Moment from 'react-moment';
import 'moment/locale/ru'
import {Link} from 'react-router-dom'
import {useCookies} from "react-cookie";


export default ({forum, img, name, dateStart, dateEnd, showBtn}) => {
    const [cookies] = useCookies(['lang']);
    let startMonth = dateStart.split('-')[1];
    let endMonth = dateEnd.split('-')[1];
    return (
        <div className="forumsEl">
            <Link to={"/" + (cookies.lang || 'ru') +'/forum-day-events/'+forum.id} replace>
                <div className="forumsEl__img">
                    {img !== undefined &&
                        <img src={img} alt=""/>
                    }
                </div>
                <div className="forumsEl__date">
                    {startMonth === endMonth ?
                        <Moment format="DD">{dateStart}</Moment>
                        :
                        <Moment format="DD MMMM">{dateStart}</Moment>
                    }
                    -
                    <Moment format="DD MMMM">{dateEnd}</Moment>
                </div>
                <div className="forumsEl__head">{name}</div>
                {/*<div className="forumsEl__text">*/}
                {/*    Подача заявок: <br/>*/}
                {/*    29 июля - 21 августа*/}
                {/*</div>*/}
            </Link>
            {showBtn &&
            <div className="forumsEl__button">
                <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}
                   className={'button button__yellow'}
                >Регистрация</a>
            </div>
            }
        </div>

    )
}