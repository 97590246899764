import React from 'react'
import Api from 'service/Api'

import Header from 'pageComponents/header'
import Banner from 'pageComponents/banner'
import Registration from 'pageComponents/registration'
import Opportunities from 'pageComponents/opportunities'
import ForumBanner from 'pageComponents/forumBanner'
import ForumBanner2 from 'pageComponents/forumBanner2'
import ActivitySlider from 'pageComponents/activitySlider'
import Partners from 'pageComponents/partners'
import Footer from 'pageComponents/footer'


import bg from 'assets/image/bannerBg.png'
import bgPeople from 'assets/image/bannerPeople.png'
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import translate from "../forumDayEvents/_translate";
import o1 from "../../assets/image/vmeste/org1.png";
import o2 from "../../assets/image/vmeste/org2.png";
import o3 from "../../assets/image/vmeste/org3.png";
import o4 from "../../assets/image/vmeste/org4.png";
import o5 from "../../assets/image/vmeste/org5.png";

let lang = 'ru'
class Main extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
    Api = new Api()

    state = {
        user: undefined
    }
    
    componentDidMount() {
        this.getUserData();
      //   lang = this.props.allCookies.lang || 'ru'
      // this.props.history.push(`${lang}/forum-day-events/6`)
    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }


    render() {
        const {user} = this.state;
        return (
            <div className="main__page page">
                <Header className={'header__orange'} user={user} getUserData={this.getUserData}/>
                <Banner
                    bg={bg}
                    bgPeople={bgPeople}
                    banner__head={`Добро пожаловать на портал <span class="no-wrap">ДОБРО в России!</span>`}
                    banner__text={`Это уникальная цифровая площадка, работающая в режиме нон-стоп, где лучшие спикеры страны, эксперты в разных областях, от предпринимательства и менеджмента до искусства и спорта, делятся своими знаниями и опытом. Каждую неделю вас ждут яркие выступления лидеров мнений, панельные дискуссии, конкурсы, программы обучений и тематические онлайн-сцены.`}
                />
                {user === false && <Registration/>}
                <Opportunities/>
                <ForumBanner2 user={user}/>
                <ForumBanner user={user}/>
                <ActivitySlider user={user}/>
                {/*<Partners/>*/}
              <div className="org">
                <div className="cnt">
                  <div className="org__head">
                    Организаторы
                    {/*{translate.Organizers.head[lang]}*/}
                  </div>
                  <div className="orgs">
                    <a href="https://fadm.gov.ru/" className="org__el"><img src={o1} alt=""/></a>
                    <a href="https://rospatriotcentr.ru/" className="org__el"><img src={o2} alt=""/></a>
                    <a href="https://xn--80ae4d.xn--p1ai/ " className="org__el"><img src={o3} alt=""/></a>
                    {/*<a href="https://xn----ctbhcbtapdmikb4a2a0m.xn--p1ai/ " className="org__el"><img src={o4} alt=""/></a>*/}
                    {/*<a href="https://contests.dobro.ru/dr" className="org__el"><img src={o5} alt=""/></a>*/}
                  </div>
                </div>
              </div>
                <Footer/>
            </div>
        )
    }
}

export default withCookies(Main)
