import React from "react";
import { Link } from 'react-router-dom'
import img from 'assets/image/forumBaner2.png'
import {useCookies} from "react-cookie";

export default ({user}) => {
    const [cookies] = useCookies(['lang']);

    return (
        <div className='forumBanner' style={{marginBottom: 30, }}>
            <div className="cnt">
                <Link to={"/" + (cookies.lang || 'ru') + "/forum-day-events/6"} className={'forumBanner__link'}>
                    <img src={img} alt="" className={'forumBanner__img'} style={{borderRadius: 30, overflow: "hidden"}}/>
                </Link>
                <div className="forumBanner__foot">
                    <div className={'forumBanner__foot-left'}>
                        <div className="forumBanner__date">4 - 5 Декабря</div>
                        <div className="forumBanner__text">Марафон #МЫВМЕСТЕ</div>
                    </div>
                </div>
            </div>
        </div>
    )
}