import React from 'react'
import Api from 'service/Api'

import Header from 'pageComponents/header-vmeste'
import Footer from 'pageComponents/footer'

import UserData from "pageComponents/userData";
import TimeTable from "pageComponents/timeTable";

import elka from '../../assets/image/vmeste/elka.png'
import translate from "../forumDayEvents/_translate";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import ava from '../../assets/image/vmeste/ava.png'

class Main extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  Api = new Api()

  state = {
    user: undefined,
    userEvents: undefined,
    userPoint: undefined,
    separatedEventsCount: undefined
  }

  componentDidMount() {
    this.getUserData();
    this.getUserEvents();
    this.getUserPoint();
    this.getSeparatedEventsCount();
    // $('.questionnaire__iframe').load(function(){
    //   var target = this;
    //   var content = $(target).contents().find('body');
    //   $(target).height($(content).outerHeight(true));
    //
    //   // $(content).on("DOMSubtreeModified click", function (event){
    //   //   setTimeout(function(){
    //   //     $(target).stop().animate({height: $(content).outerHeight(true)}, 200);
    //   //   }, 400);
    //   // });
    // });
    //   var content = $('.questionnaire__iframe').contents().find('body');
    //   $('.questionnaire__iframe').height($(content).outerHeight(true));
  }

  getUserData = () => {
    this.Api.getUserData()
      .then(user => {
        if (!user) this.props.history.push('/');
        this.setState({user});

      })
      .catch(e => console.log('error', e))
  }

  getUserEvents = () => {
    this.Api.getUserEvents()
      .then((userEvents) => {
        this.setState({userEvents})
      })
      .catch(e => console.log('error', e))
  }

  getUserPoint = () => {
    this.Api.getUserPoint()
      .then((userPoint) => {
        this.setState({userPoint})
      })
      .catch(e => console.log('error', e))
  }

  getSeparatedEventsCount = () => {
    this.Api.getSeparatedEventsCount()
      .then((separatedEventsCount) => {
        this.setState({separatedEventsCount})
      })
      .catch(e => console.log('error', e))
  }
  logout = () => {
    const {getUserData} = this.props
    this.Api.logout()
      .then(() => {this.props.history.push('/')});
  }

  render() {
    const {user, userEvents, userPoint, separatedEventsCount} = this.state;
    if (!user) return <div/>
    console.log(user)
    return (
      <div className="profile__page page">
        <Header className={'header__orange'} user={user} getUserData={this.getUserData} translate={translate} lang={this.props.allCookies.lang || 'ru'} bg={true}/>
        <div className="profile__info">
          <div className="cnt">
            <div className="ava">
              <img src={user.image || ava} alt="" className={user.image ? 'cover' : 'contain'}/>
            </div>
            <div className="name">
              {user.name} {user.surname}&nbsp;
              <small
                style={{fontSize: '60%', cursor: 'pointer'}}
                onClick={this.logout}
              >
                                    ({translate.profile.logout[this.props.allCookies.lang || 'ru']})
                                </small>
            </div>
          </div>
        </div>
        {userEvents && Object.keys(userEvents).length ?
          <div className="programs programmVmeste">
            <TimeTable userEvents={userEvents} translate={translate} lang={this.props.allCookies.lang || 'ru'}/>
          </div>
          :
          <div/>
        }

        <div className="cnt">
          <a href="https://xn--80aanbeohciex.xn--p1ai/" target="_blank" className="elka">
            <div className="elka__desc">
              <div className="elka__head">
                {translate.elka.head[this.props.allCookies.lang || 'ru']}
              </div>
              <div className="elka__text">
                {translate.elka.text[this.props.allCookies.lang || 'ru']}
              </div>
              <div className="elka__footer">
                {translate.elka.footer[this.props.allCookies.lang || 'ru']}
              </div>
            </div>
            <img src={elka} alt="" className="elka__img"/>
          </a>
        </div>
        
        <div className="cnt">
          <iframe
            className='questionnaire__iframe'
            src="https://dobroinrussia.online/qeuestionnaire/6"
            frameborder="0"
            width="100%"
            // onload={(o) => {o.style.height=o.contentWindow.document.body.scrollHeight+"px";}}
          />
        </div>
        
        <div className="org">
          <div className="cnt">
            <div className="org__head">
              {translate.Organizers.head[this.props.allCookies.lang || 'ru']}
            </div>
            <div className="orgs">
              <a target='blank' href="https://fadm.gov.ru/" className="org__el"><img src='https://static.tildacdn.com/tild6565-6532-4239-b764-643865303463/rosmolodezh-orange.svg' alt=""/></a>
              <a target='blank' href="https://rospatriotcentr.ru/" className="org__el"><img src='https://thumb.tildacdn.com/tild6365-6661-4464-a662-353131633432/-/resize/130x/-/format/webp/photo.png' alt=""/></a>
              <a target='blank' href="https://xn--80ae4d.xn--p1ai/ " className="org__el"><img src='https://thumb.tildacdn.com/tild3564-3064-4262-a233-336131343333/-/resize/190x/-/format/webp/main_logo-png.png' alt=""/></a>
              <a target='blank' href="https://contests.dobro.ru/dr" className="org__el"><img src='https://thumb.tildacdn.com/tild3563-3561-4038-b730-306231346332/-/resize/100x/-/format/webp/AVC_Konk_Logo20.png' alt=""/></a>
              <a target='blank' href="https://xn----ctbhcbtapdmikb4a2a0m.xn--p1ai/ " className="org__el"><img src='https://thumb.tildacdn.com/tild3662-3537-4566-b636-346631386362/-/resize/180x/-/format/webp/-.png' alt=""/></a>
            </div>
          </div>
        </div>

        <Footer/>
      </div>
    )
  }
}

export default withCookies(Main)
