import React from "react";

export default () => {
    return (
        <div className="registration">
            <div className="cnt">
                <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}
                    className={'button button__yellow button__big'}
                >Регистрация</a>
                <div className="registration__text">
                    Нажимая на кнопку, вы даете согласие на обработку персональных<br/>
                    данных и соглашаетесь c политикой конфиденциальности
                </div>
            </div>
        </div>
    )
}