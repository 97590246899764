import React from "react";
import Api from "../../service/Api";
import Header from "../../pageComponents/header";
import Footer from "../../pageComponents/footer";
import LessonTypeText from "../../components/lessonSteps/typeText";
import LessonTypeVideo from "../../components/lessonSteps/typeVideo";
import LessonInteractiveVideo from "../../components/lessonSteps/typeInteractiveVideo";
import LessonInteractive from "../../components/lessonSteps/typeInteractive";
import _, { map } from 'underscore';
import QuestionCorrect from "../../components/LessonAndTestComponents/QuestionCorrect";

export default class Lesson extends React.Component {

    Api = new Api()

    state = {
        user: undefined,
        lesson_step: undefined,
        answer: '',
        ext: undefined,
        equal: undefined,
        showCorrect: false,
    }

    componentDidMount() {
        console.log(this.props.match.params.id)
        this.getUserData();
        this.getLessonData(this.props.match.params.id, undefined);


    }

    getUserData = () => {
        this.Api.getUserData()
            .then(user => this.setState({user}))
            .catch(e => console.log('error', e))
    }

    getLessonData = (lesson_id, order, step_id) => {
        if (order){
            this.Api.setLessonStats(lesson_id, step_id)
                .then(() => {
                    this.Api.getLessonStep(lesson_id, order)
                        .then(lesson_step => {
                            console.log('lesson_step', lesson_step)
                            lesson_step != null ?
                                this.setState({
                                    lesson_step,
                                    answer: '',
                                    ext: undefined,
                                    equal: undefined,
                                    free: undefined,
                                    showCorrect: false
                                })
                                :
                                this.props.history.push('/test')
                        })
                })
        }else {
            this.Api.getLessonStep(lesson_id, order)
                .then(lesson_step => this.setState({lesson_step}))
        }
    }

    setLessonData = (lesson_step) => {
        const {answer, ext, equal, showCorrect} = this.state
        if(equal !== undefined && showCorrect === false){
            this.setState({
                showCorrect: true,
            })
            if(lesson_step.content.answer_type == 'free' || lesson_step.content.answer_type == 'upload'){
                this.setState({free: true})
            }
        }else{
            this.Api.setLessonData(lesson_step.attributes.id, lesson_step.content.answer_type, answer, ext)
                .then(res => {
                    if(res){
                        this.setState({
                            answer: '',
                            ext: undefined,
                            equal: undefined,
                            free: undefined,
                            showCorrect: false
                        })
                        this.getLessonData(lesson_step.attributes.lesson_id, lesson_step.attributes.order, lesson_step.attributes.id)
                    }
                })
        }

    }

    setAnswerProps = (answer, ext, checked, matchesOut) => {
        this.setState({answer})
        this.state.ext = ext;
        this.state.equal = _.isEqual(checked, matchesOut);
    }

    _renderStep = (type) => {
        const {lesson_step, user, answer} = this.state;
        switch (type) {
            case 1: {
                return <LessonTypeText content={lesson_step.content}/>
            }
            case 2: {
                return <LessonTypeVideo content={lesson_step.content}/>
            }
            case 3: {
                if(user) {
                    return <LessonInteractiveVideo
                        content={lesson_step.content}
                        attributes={lesson_step.attributes}
                        user={this.state.user}
                    />
                }
                return <div/>
            }
            case 4: {
                return <LessonInteractive
                    answer={answer}
                    content={lesson_step.content}
                    matches={lesson_step.matches}
                    setAnswerProps={this.setAnswerProps}
                />
            }
        }
    }


    render() {
        console.log('render', this.state)
        const {user, lesson_step, showCorrect, equal, free} = this.state
        return (
            <div className="training__page page">
                <Header className={'header__orange'} user={user} getUserData={this.getUserData}/>
                {lesson_step &&
                    <div className={'cnt'}>
                        <div className="step">
                            <div className="step__head">
                                {lesson_step.attributes.name}
                            </div>
                            {this._renderStep(lesson_step.attributes.type)}
                            {showCorrect &&
                            <QuestionCorrect
                                type={free ? 'neutral' : (equal ? 'correct': 'inCorrect')} // correct - верный, inCorrect - не выерный
                                head={free ? 'Спасибо за ответ' : (equal ? 'Все верно': 'К сожалению вы ошиблись')} // Заголовок блока
                                text={''} // Текст блока
                            />
                            }
                            <div className="step__btn">
                                <button
                                    type={'button'}
                                    className="button button__orange"
                                    onClick={() => {
                                        lesson_step.attributes.type !== 4 ?
                                        this.getLessonData(lesson_step.attributes.lesson_id, lesson_step.attributes.order, lesson_step.attributes.id)
                                        :
                                        this.setLessonData(lesson_step)
                                    }}
                                >
                                    Далее
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <Footer/>

            </div>
        )
    }
}