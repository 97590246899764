import React from 'react'
import './style.sass'

export default props => {
    return (
        <div className={"QuestionCorrect " + props.type}>
            <div className="head" dangerouslySetInnerHTML={{ __html: props.head }}/>
            <div className="text" dangerouslySetInnerHTML={{ __html: props.text }}/>
        </div>
    )
}