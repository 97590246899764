import React from "react";
import Button from 'components/button'
import Moment from "react-moment";
import translate from "../../page/forumDayEvents/_translate";

export default ({translate, lang, style, className, bg, bgPeople, banner__date_start, banner__date_end, banner__head, banner__text, banner__btn, banner__btn_click, user}) => {
    let startMonth = ''
    let endMonth = ''
    if(banner__date_start) {
        startMonth = banner__date_start.split('-')[1];
        endMonth = banner__date_end.split('-')[1];
    }
    if (className !== 'bvmeste') {
        return (
            <div className={"banner "} style={style}>
                <div className="cnt">
                    {banner__date_start &&
                    <div className="banner__date">
                        {startMonth === endMonth ?
                            <Moment format="DD">{banner__date_start}</Moment>
                            :
                            <Moment format="DD MMMM">{banner__date_start}</Moment>
                        }
                        -
                        <Moment format="DD MMMM">{banner__date_end}</Moment>
                    </div>
                    }
                    {banner__head &&
                    <div className="banner__head" dangerouslySetInnerHTML={{__html: banner__head}}/>
                    }
                    {banner__text &&
                    <div className="banner__text" dangerouslySetInnerHTML={{__html: banner__text}}/>
                    }
                    {banner__btn &&
                    <Button
                        className={'button__orange button__big'}
                        text={banner__btn}


                    />
                    }
                    {className == 'vmeste' && !user &&
                    <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}
                       className={'banner__btn'}
                    >
                        Регистрация
                    </a>
                    }
                </div>
                {bg &&
                <img src={bg} alt="" className={'banner__bg'}/>
                }
                {bgPeople &&
                <img src={bgPeople} alt="" className={'banner__bgPeople'}/>
                }
            </div>
        )
    }else{
        return (
            <div className={"banner " + className} style={style}>
                <div className="cnt">
                    <div className="banner__head">
                        {translate.banner.head[lang]}
                    </div>
                    <div className="banner__subHead">
                        {translate.banner.subhead[lang]}
                    </div>
                    <div className="banner__text2">
                        {translate.banner.text1[lang]}
                    </div>
                    <div className="banner__text3">
                        {translate.banner.text2[lang]}
                    </div>
                    <div className="banner__text4">
                        {translate.banner.text3[lang]}
                    </div>

                </div>
                {bg &&
                <img src={bg} alt="" className={'banner__bg'}/>
                }
                {bgPeople &&
                <img src={bgPeople} alt="" className={'banner__bgPeople'}/>
                }
                <div className="banner__foter">
                        <div className="cnt">
                            {translate.banner.text4[lang]}
                            {!user &&
                            <a href={'https://dobro.ru/vacancy/10028168?authorized=true'}
                               className={'banner__btn'}
                            >
                                {translate.banner.btn[lang]}
                            </a>
                            }
                    </div>
                </div>
            </div>
        )
    }
}