import React from 'react';
import PanelStudy from "../../components/PanelStudy";
import CourseStat from "../../components/CourseStat";
import './style.sass'
import Api from "../../service/Api";
import nullImg from '../../assets/image/no-image.png';


export default class MyTraining extends React.Component {

    Api = new Api();

    state = {
        courses: {},
        lessonActive: false,
        showPopapEnd: false,
        showPopupExpert: false,
        popupExpertActive: 0
    }


    componentDidMount() {
        this.setState({
            lessonActive: false,
        })
        this.Api.getLessonCourses()
            .then(this.getLessonsSuccess)
    }

    getLessonsSuccess = (courses) => {
        console.log('courses', courses)
        this.setState({courses})
    }

    _renderLessonPanel = () => {
        const {courses} = this.state
        if (courses !== undefined && Object.values(courses).length) {
            return (
                Object.values(courses).map((course, index) => {
                    let courseAttr = course.course.attributes
                    let lessonAttr = course.course.lessons
                    let pointTotal = 0
                    return (
                        <div key={courseAttr.created_at}>
                            <div>
                                <div className="content_head">
                                    {courseAttr.name}
                                </div>
                                <div className="studyPanels">
                                    {(lessonAttr !== undefined && Object.values(lessonAttr).length) ?
                                    Object.values(lessonAttr).map(lesson => {
                                        pointTotal = pointTotal + lesson.points
                                        let img = lesson.attributes.image == null ? nullImg : 'http://rp-study.dg-prod.su/' + lesson.attributes.image;
                                        return (
                                            <PanelStudy
                                                key={lesson.attributes.id}
                                                lesson={lesson}
                                                img={img}
                                                id={lesson.attributes.id}
                                                onClick={this.changeLessonActive}
                                            />
                                        )
                                    })
                                        :
                                        undefined
                                    }
                                    <CourseStat courses={course.course} pointTotal={pointTotal}/>
                                </div>
                            </div>
                        </div>
                    )
                })
            )
        } else {
            return undefined;
        }
    }





    render() {
        return (
            <div>
                {this._renderLessonPanel()}
            </div>
        )
    }
}

