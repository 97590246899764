import React from 'react'
import MainPage from "page/main";
import ForumDayEventsPage from "page/forumDayEvents";
import PartnersPage from "page/partners";
import ProjectPage from "page/project";
import ForumsPage from "page/forums";
import ProfilePage from "page/profile/vmeste";
import TrainingPage from "page/training";
import LessonPage from "page/lesson";
import TestPage from "page/test";
import TestOnePage from "page/testOne";
import {Switch, Route, Redirect} from 'react-router-dom'

export default class Main extends React.Component {
    render() {
        const supportedLanguages = ["en", "ru"];
        return (
            <Switch>
                <Route exact path='/' component={MainPage}/>
                <Route exact path='/ru' component={MainPage}/>
                <Route exact path='/en' component={MainPage}/>
                {/*<Route path='/:lang?/' component={MainPage}/>*/}
                <Route path='/forum-day-events/:id' component={ForumDayEventsPage}/>
                <Route path='/ru/forum-day-events/:id' component={ForumDayEventsPage}/>
                <Route path='/en/forum-day-events/:id' component={ForumDayEventsPage}/>
                <Route path='/:lang?/partners/:id' component={PartnersPage}/>
                <Route path='/:lang?/project/:id' component={ProjectPage}/>
                <Route path='/:lang?/forums' component={ForumsPage}/>
                <Route path='/:lang?/profile' component={ProfilePage}/>
                <Route path='/:lang?/training' component={TrainingPage}/>
                <Route path='/:lang?/lesson/:id' component={LessonPage}/>
                <Route path='/:lang?/test' component={TestPage}/>
                <Route path='/:lang?/testOne' component={TestOnePage}/>
                <Route
                    path="/:lang"
                    render={props =>
                        props.match.params.lang !== undefined
                            ? supportedLanguages.includes(props.match.params.lang)
                            ? <MainPage {...props} />
                            : <Redirect to={`/ru/${props.match.params.lang}`}/>
                            : <Redirect to={`/ru/${props.match.params.lang}`}/>
                    }
                />
            </Switch>
        )
    }
}
