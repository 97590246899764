import React from "react";
import HeaderMenu from './menu'
import HeaderAccount from './account'
import logoImg from 'assets/image/logo.png'

import {Link} from 'react-router-dom'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';



class header extends React.Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    state = {
        navListShow: true
    }

    componentDidMount() {
        let scrollWidth = Math.max(
            document.body.scrollWidth, document.documentElement.scrollWidth,
            document.body.offsetWidth, document.documentElement.offsetWidth,
            document.body.clientWidth, document.documentElement.clientWidth
        );
        if(scrollWidth < 768){
            this.setState({navListShow: false})
        }
    }

    onClick = () => {
        this.setState({navListShow: !this.state.navListShow})
    }
    render() {
        const {className, user, getUserData, style} = this.props
        const {navListShow} = this.state
        return (
            <header className={"header " + className} style={style}>
                <div className="cnt">
                    <Link to={"/" + (this.props.allCookies.lang || 'ru')} className="header__logo">
                        <img src={logoImg} alt=""/>
                    </Link>
                    <div className={'right'}>
                        <nav className="header__navigation navigation">
                            <div
                                className="header__navigation-burger"
                                onClick={this.onClick}
                            >
                                <svg width="30" height="22" viewBox="0 0 30 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 2.07692C0 0.92987 0.92987 0 2.07692 0H27.9231C29.0701 0 30 0.92987 30 2.07692C30 3.22398 29.0701 4.15385 27.9231 4.15385H2.07692C0.929871 4.15385 0 3.22398 0 2.07692Z"
                                        fill="white"/>
                                    <rect y="8.9231" width="30" height="4.15385" rx="2.07692" fill="white"/>
                                    <rect y="17.8462" width="30" height="4.15385" rx="2.07692" fill="white"/>
                                </svg>
                            </div>
                            <HeaderMenu navListShow={navListShow} />
                        </nav>
                        <HeaderAccount user={user} getUserData={getUserData}/>
                    </div>
                </div>

            </header>
        )
    }
}

export default withCookies(header)