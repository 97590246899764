import React from "react";
import img from 'assets/image/partnerEl.png'
import Api from "../../service/Api";
import {Link} from "react-router-dom";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";

class Partners extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    Api = new Api()

    state = {
        partners: undefined
    }

    componentDidMount() {
        this.getPartners();
    }

    getPartners = () => {
        this.Api.getAllPartners('6', this.props.forum_id)
            .then(partners => this.setState({partners}))
            .catch(e => console.log('error', e))
    }

    render() {
        const {partners} = this.state;
        const {showDesc} = this.props;
        return (
            <div className='partners'>
                <div className="cnt">
                    <div className="partners__head">
                        Партнёры
                    </div>
                    <div className="partners__flex">
                        {partners && Object.values(partners).map((partner => {
                            return (
                                <div className="partner_el">
                                    <Link to={"/" + (this.props.allCookies.lang || 'ru') +'/partners/' + partner.id} className="partner_el-icon">
                                        <div className="partner_el-img">
                                            <img src={'https://dobroinrussia.online/uploads/' + partner.logo} alt=""/>
                                        </div>
                                    </Link>
                                        <div className="partner_el-desc">
                                            <div className="name">{partner.name}</div>
                                            {showDesc === 'true' ?
                                                <div className="description" dangerouslySetInnerHTML={{__html: partner.description}}/>
                                                :
                                                undefined
                                            }
                                        </div>

                                </div>
                            )
                        }))}
                    </div>
                </div>
            </div>
        )
    }
}

export default withCookies(Partners)