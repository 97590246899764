import React, {useEffect} from "react";
import moment from "moment";

const ProgramList = ({actualForum}) => {
  console.log(actualForum)
  // useEffect(() => {
  //   i = 0
  //   let end, now, next1, next2, next3;
  //   let program__el = document.getElementsByClassName('program__el')
  //     Array.prototype.forEach.call(program__el, function (el) {
  //       el.hidden = true
  //       if(el.classList.contains('list_el-end')){
  //         if (end) end.hidden = true
  //         end = el
  //         el.hidden = false
  //       }
  //       if(el.classList.contains('list_el-now') && !now){
  //         now = el
  //         el.hidden = false
  //       }
  //       if(el.classList.contains('list_el-next')){
  //         if(!next1) {
  //           next1 = el
  //           el.hidden = false
  //         }else if(!next2){
  //           next2 = el
  //           el.hidden = false
  //         }else if(!next3){
  //           next3 = el
  //           el.hidden = false
  //         }
  //       }
  //     });
  // })
  return (
    <div className="program__list">
      {Object.values(actualForum).map(day => {
        return (
          day.map(event => {
            console.log('event', event)
            return (
              <div className={"program__el " + (event.current ? 'list_el-next' : 'list_el-next')}>
                <div className="left">
                  <div className="time">{event.hour_start} {event.hour_end}</div>
                </div>
                <div className="right">
                  <div className="name">{event.name}</div>
                </div>
              </div>
            )
          })
        )

        // return (
        //   forumData.forumDaysEvent[day.id].map(event => {
        //     if (event.hidden == 0) {
        //       i++;
        //       return (
        //         <div className={"program__el " + className[i - 1]}>
        //           <div className="left">
        //             <div className="time">{event.hour_start} {event.hour_end}</div>
        //           </div>
        //           <div className="right">
        //             <div className="name">{event.name}</div>
        //           </div>
        //         </div>
        //       )
        //     }
        //   })
        // )
      })
      }
    </div>
  )
}

export default ProgramList