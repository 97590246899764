import React from 'react'
import './style.sass'
import {Link} from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class PanelStudy extends React.Component{
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    state = {
        team: '',
        progress: ''
    };

    componentDidMount() {
        this.setTeam();
    }

    setTeam = () => {
        const {lesson} = this.props;
        let progress = Math.round((lesson.steps / lesson.attributes.steps) * 100)
        let team = {
            baseBgColor: '#fff',
            baseTextColor: '#FF6700',
            btnClass: 'button__orange',
            starLineColor: '#FF6700'
        }
        if(progress !== 100 && progress !== 0) {
            team = {
                baseBgColor: '#FF6700',
                baseTextColor: '#fff',
                btnClass: 'button__white',
                starLineColor: '#fff'
            }
        }
        this.setState({
            team,
            progress
        })

    }

    render() {
        const {team, progress} = this.state;
        const {lesson} = this.props;

        return (
            <div
                className={'PanelStudy'}
                style={{backgroundColor: team.baseBgColor}}
            >
                {progress !== 0
                    &&
                    <div className="done">
                    {progress === 100 ?
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" >
                            <circle cx="15" cy="15" r="15" fill="#CCDFEF"/>
                            <line x1="8.12132" y1="15" x2="13" y2="19.8787" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round"/>
                            <line x1="22" y1="11.1213" x2="13.1213" y2="20" stroke="white" strokeWidth="3" strokeLinecap="square" strokeLinejoin="round"/>
                        </svg>
                        :
                        <span>
                            пройдено {progress}%
                        </span>
                    }
                </div>
                }
                <div className="name" style={{color: team.baseTextColor}}>{lesson.attributes.name}</div>
                <div className="desc">
                    <div className="img">
                        <img src={this.props.img} alt=""/>
                    </div>
                    <div className="text">
                        <span style={{color: team.baseTextColor}} dangerouslySetInnerHTML={{ __html: lesson.attributes.info_text }}/>
                        <Link to={"/" + (this.props.allCookies.lang || 'ru') + '/lesson/'+lesson.attributes.id} replace className={'button ' + team.btnClass}>
                            {progress === 0 ? 'Начать урок' : progress === 100 ? 'пройти снова' : 'продолжить'}
                        </Link>
                    </div>
                </div>
                <div className="star" style={{color: team.baseTextColor}}>
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill={team.baseTextColor} fillRule="evenodd" clipRule="evenodd" d="M10.4853 0L14.1566 6L21 7.64706L16.4476 13L16.9762 20L10.4853 17.3235L3.99441 20L4.55245 13L0 7.64706L6.81399 6L10.4853 0ZM10.4853 2.67647L7.69511 7.20588L2.55524 8.47059L6.02098 12.5294L5.60979 17.8235L10.5147 15.7941L15.4196 17.8235L15.0084 12.5294L18.4448 8.47059L13.2755 7.23529L10.4853 2.67647Z" />
                    </svg>
                    {lesson.points} баллов
                    <div className="line" style={{backgroundColor: team.starLineColor}}/>
                </div>
            </div>
        )
    }
}

export default withCookies(PanelStudy)